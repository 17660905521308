import React, { useState, useEffect } from "react";
import axios from 'axios';
import { css } from "glamor";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "./gridindex.css";
import "./getContactColumn.css";
import { API_BASE_URL, myConstClass, EDIT_SVG, CANCEL_SVG, SAVE_SVG, TRASH_ICON, styles } from "./constants.js";
import DropdownWithMeta from './DropdownWithMeta.js';
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import isURL from 'validator/lib/isURL';
import uuid from 'uuid-random';
import { getAllRowsData, trimOrSendEmpty, check_mandatory_validate_contactFields, check_mandatory_validate_accountFields, getAllRowsDatawithId, saveAccountfromRow, saveFundingfromRow, saveCRMfromRow, saveContactfromRow } from './functionRepositoryDataSaveGet';
import { validateUrl, validateEmail, validateStringRegex, validateFloat, validateInteger } from './validateInput';
import TypeAheadService from './TypeAheadService'
import TypeAheadServiceContact from './TypeAheadServiceContact';
import ValidationUIComponent from './ValidationUIComponent';
import ContactFirstName from './ContactFirstName';
import './toast.css';
import Alert from '@mui/material/Alert';


const localhost = myConstClass.localhost;

var personaLevels = [];
var personaCodes = [];
var departments = [];
var contactRoles = [];
// var subdepartments = [];
var countriesList = [];
var personaCode = [];
var capillaryGeography = [];
var crmContactRegionsList = [];
var contactSource = [];
let allMetaDataList = [];

var suggestionSelected = false;

// var statesListByCountry = [];
//const localhost = window._env_.API_DOMAIN;

toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_LEFT,


});



  function setDisable(){
    return false;
  }


const GetColumns = ({ setRowsData, metadata, stateMetaData, allMetadata }) => {
  allMetaDataList = [...allMetadata]
  if (metadata != undefined && metadata.length > 0) {
    departments = metadata.find(obj => obj.name == "department");
    if (departments) {
      departments = departments.values;
    }
    personaLevels = metadata.find(obj => obj.name == "personaLevel");
    if (personaLevels) {
      personaLevels = personaLevels.values;
    }


    contactRoles = metadata.find(obj => obj.name == "contactRole");
    if (contactRoles) {
      contactRoles = contactRoles.values;
    }

    contactSource = metadata.find(obj => obj.name == "contactSource");
    if (contactSource) {
      contactSource = contactSource.values;
    }

    personaCodes = metadata.find(obj => obj.name == "personaCode");
    if (personaCodes) {
      personaCodes = personaCodes.values;
    }
    countriesList = metadata.find(obj => obj.name == "country");
    if (countriesList) {
      countriesList = countriesList.values;
    }
    personaCode = metadata.find(obj => obj.name == "personaCode")
    if (personaCode) {
      personaCode = personaCode.values;
    }
    capillaryGeography = metadata.find(obj => obj.name == "geography")
    if (capillaryGeography) {
      capillaryGeography = capillaryGeography.values;
    }
    else {
      capillaryGeography = []
    }
    crmContactRegionsList = metadata.filter(obj => obj.name == "accountRegion");
    if (crmContactRegionsList) {
      if (crmContactRegionsList.length === 1) {
        crmContactRegionsList = crmContactRegionsList[0].values;
      } else if (crmContactRegionsList.length === 2) {
        crmContactRegionsList = crmContactRegionsList[1].values;
      } else {
        crmContactRegionsList = [];
      }
    }
    // statesListByCountry = stateMetaData;
  }

  const userData = useAuthState();
  let tenantId = userData.user.tenantId;
  let userId = userData.user.userUuid;

  // useEffect(() => {
  //   console.log("options from useffect",options)
  // },[options]);

  var saveadapt = {


    "tenantId": tenantId,// "TTT-ID-00000",
    "userId": userId,// "USR-ID-0000",
    "msg": "",
    "rowSelected": []
  }
  const deleteSingleRow = (e, rowIndex, rowsClone) => {
    console.log(rowsClone)
    if (rowsClone[rowIndex - 1]["contactId"] === "") {

      toast.error(<Alert severity="error">First Empty row cannot be deleted</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true,
      });
    }
    else {
      // console.log(rowIndex);
      var cId = rowsClone[rowIndex - 1]["contactId"];
      console.log(cId);
      axios.delete(localhost + "/b2pm/deletedata/" + tenantId + "/" + userId + "/contact/" + cId, { withCredentials: true })
      rowsClone.splice(rowIndex - 1, 1);
      var data = rowsClone;
      setRowsData([...data]);

    }


  }



  function addNewRow(rowsClone, z) {


    var obj = {
      "id": uuid(),
      "row_id": z,
      "msg": "",
      "accountSource": "",
      "accountId": "",
      "companyName": "",
      "companyGroupName": "",
      "companyWebsite": "",
      "companyParentWebsite": "",
      "companyGroupWebsite": "",
      "companyPrimaryIndustryCategory": "",
      "companySubIndustryCategory": "",
      "companyBrandRevenue": "",
      "companyEmployeeCount": "",
      "companyFax": "",
      "companyPhone": "",
      "companyStreet": "",
      "companyCity": "",
      "companyState": "",
      "companyCountry": "",
      "companyContinent": "",
      "companyZipCode": "",
      "companyDescription": "",
      "companyLinkedin": "",
      "companyFacebook": "",
      "companyTwitter": "",
      "companyParent": "",
      "companyHierarchy": "",
      "companyLocationCount": "",
      "companyProducts": "",
      "companyType": "",
      "companyClassification": "",
      "companyTechAttributes": "",
      "companyHashTag": "",
      "companyRecentFundingAmount": "",
      "companyRecentFundingDate": "",
      "companyTotalFundingAmount": "",
      "companyFundingList": "",
      "companyAccountRegion": "",
      "crmFmExecutive": "",
      "crmNumberofStores": "",
      "crmBusinessFormat": "",
      "crmISExecutive": "",
      "crmFSExecutive": ""
    };

    var data = rowsClone;
    data.unshift(obj);

    setRowsData([...data]);

    console.log(rowsClone);

    //  console.log(rowsClone[0].id);
    //  console.log(rowsClone[1].id);
    //  console.log(rowsClone[0].id === rowsClone[1].id);
    if (rowsClone.length > 1) {
      if (rowsClone[0].id === rowsClone[1].id) {

        rowsClone.splice(1, 1);
        setRowsData(rowsClone)
      }
    }
  }





  //  function check_mandatory_contactFields(rowsClone, updatedRowIndex){
  //  if ((!trimOrSendEmpty(rowsClone[updatedRowIndex].companyWebsite)) || (!trimOrSendEmpty(rowsClone[updatedRowIndex].companyName)) || !(trimOrSendEmpty(rowsClone[updatedRowIndex].contactLastName)) || !(trimOrSendEmpty(rowsClone[updatedRowIndex].contactFirstName)) || !(trimOrSendEmpty(rowsClone[updatedRowIndex].contactEmail)) ) {
  //  console.log("mandatory fields", rowsClone[updatedRowIndex].companyWebsite, rowsClone[updatedRowIndex].companyName, rowsClone[updatedRowIndex].contactFirstName, rowsClone[updatedRowIndex].contactLastName, rowsClone[updatedRowIndex].contactEmail)
  //   alert("No Mandatory fields passed");
  //  return false;
  //  } else { 
  //  return true;
  //  }
  //  }






  function checkEmail(rowsClone, updatedRowIndex) {
    if (rowsClone.length <= 1) {

      console.log(updatedRowIndex);
      console.log(rowsClone[updatedRowIndex]);
      console.log(rowsClone);
      return false;
    }

    for (let i = 1; i < rowsClone.length; i++) {
      console.log("check Email", rowsClone[i]);



      // if(trimOrSendEmpty(rowsClone[updatedRowIndex].contactEmail) === trimOrSendEmpty(rowsClone[i].contactEmail) ||
      // trimOrSendEmpty(rowsClone[updatedRowIndex].contactEmail) === trimOrSendEmpty(rowsClone[i].contactSecondaryEmail) ||
      // trimOrSendEmpty(rowsClone[updatedRowIndex].contactSecondaryEmail) === trimOrSendEmpty(rowsClone[i].contactEmail) ||
      // trimOrSendEmpty(rowsClone[updatedRowIndex].contactSecondaryEmail) === trimOrSendEmpty(rowsClone[i].contactSecondaryEmail))
      if (rowsClone[updatedRowIndex].contactEmail.trim().toLowerCase() === rowsClone[i].contactEmail.trim().toLowerCase()) {
        if (updatedRowIndex !== i) {
          console.log("Check email matching")
          return true;
        }

        // alert("Entered Company Website " + rowsClone[updatedRowIndex].companyWebsite.trim().toLowerCase() + " is already present in the grid.")
        // rowsClone.splice(1,1);
        // setRowsData(rowsClone);


      }
    }
    console.log("No email found")
    return false;




  }




  const save_data = async (rowsClone, updatedRowIndex) => {
    // gridData = rowsClone;
    //console.log(gridData);

    // console.log("SAVEDATA");


    // console.log(rowsClone);
    // console.log(updatedRowIndex);
    // console.log("-------------")


    var json = { "row_id": updatedRowIndex + 1, "contactSource": rowsClone[updatedRowIndex].contactSource, "msg": rowsClone[updatedRowIndex].msg };

    var contact = saveContactfromRow(rowsClone[updatedRowIndex])


    json["contact"] = contact;
    var account = saveAccountfromRow(rowsClone[updatedRowIndex])
    json["account"] = account;

    var funding = saveFundingfromRow(rowsClone[updatedRowIndex])
    json["funding"] = funding;
    var crm = saveCRMfromRow(rowsClone[updatedRowIndex])
    json["crm"] = crm;
    saveadapt.rowSelected = [];
    saveadapt.rowSelected.push(json);
    // console.log("Push SAVE contact DATA");
    // console.log(saveadapt)


    var resp = "";
    console.log("saving data", saveadapt)
    resp = await axios.post(localhost + "/b2pm/savedata/contact", saveadapt, { withCredentials: true });

    console.log("saving data", rowsClone)


    if (resp.status == 200) {

      if ((resp.data.rowSelected[0].msg === "SUCCESS") &&
        (resp.data.rowSelected[0].account.accountId)) {

        toast.success(<Alert severity="success">Contact has been added/edited successfully!</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true,
        });

        // rowsClone[updatedRowIndex] = resp.data.rowSelected[0]
        console.log("BEFORE");
        console.log("saving data", rowsClone);
        console.log("saving data", updatedRowIndex)
        rowsClone[updatedRowIndex] = getAllRowsData(resp.data.rowSelected[0], updatedRowIndex)
        console.log("saving data", getAllRowsData(resp.data.rowSelected[0], updatedRowIndex + 1))
        setRowsData(rowsClone);
        console.log("AFTER");
        console.log("saving data", rowsClone);

      }
      else {
        
        toast.error(<Alert severity="error">{resp.data.rowSelected[0].msg} Data saving unsuccessful.</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true,
        });
        //   let newObj = addNewOnDelete(rowsClone, )
        //   console.log(rowsClone)
        //  // console.log(rowsClone[updatedRowIndex])
        //  console.log(rowsClone)
        //   var dataRow=rowsClone.splice(updatedRowIndex,1)

        //setRowsData(dataRow);
        // var dataRows = rowsClone;
        //  console.log(dataRows)
        //  console.log(newObj)
        // setRowsData([...dataRows])
        // //   //addNewRow()

      }



    }




  }

  function setDisable(){
    return false;
  }

  return [
    {
      id: 'checkbox',
      visible: true,
      pinned: true,
      width: '54px',
    },
    //  {
    //  id: 'contactId',
    //  field: 'contactId',
    //  label: 'ContactID',
    //  visible: false,
    //  },
    //  {
    //  id: 'accountId',
    //  field: 'accountId',
    //  label: 'AccountID',
    //  visible: false,
    //  },
    {
      id: "companyName",
      field: "companyName",
      label: "Company Name",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      headerCellRenderer: ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => (<span onChange={onChange} required>Company Name<span id="red_color">&#42;</span></span>),
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <TypeAheadService value={value} data={data} column={column} onChanged={onChange} disabled={false} tab="contact" suggestionSelected = {suggestionSelected}/>
    },


    {
      id: "companyWebsite",
      field: "companyWebsite",
      label: "Company Website",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      headerCellRenderer: ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => (<span onChange={onChange} required>Company Website<span id="red_color">&#42;</span></span>),
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <TypeAheadService value={value} data={data} column={column} onChanged={onChange} disabled={false} tab="contact" suggestionSelected = {suggestionSelected}/>
    },

    {
      id: 'contactFirstName',
      field: 'contactFirstName',
      label: 'First Name',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      headerCellRenderer: ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => (<span onChange={onChange} required>First Name <span id="red_color">&#42;</span></span>),
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <TypeAheadServiceContact value={value} data={data} column={column} onChanged={onChange} />

    },
    {
      id: 'contactLastName',
      field: 'contactLastName',
      label: 'Last Name',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      headerCellRenderer: ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => (<span onChange={onChange} required>Last Name <span id="red_color">&#42;</span></span>),
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <TypeAheadServiceContact value={value} data={data} column={column} onChanged={onChange} />


    },
    {
      id: 'contactEmail',
      field: 'contactEmail',
      label: 'Official Email ID',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      headerCellRenderer: ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => (<span onChange={onChange} required>Official Email ID<span id="red_color">&#42;</span></span>),
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange,
        onFocusLost,
        fieldType
      }) => <TypeAheadServiceContact value={value} data={data} column={column} onChanged={onChange} validator={validateEmail} message=" not a valid email"/>,

    },

    {
      id: 'contactSecondaryEmail',
      field: 'contactSecondaryEmail',
      label: 'Other Email ID',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <TypeAheadServiceContact value={value} data={data} column={column} onChanged={onChange} validator={validateEmail} message=" not a valid email"/>

    },
    //<TypeAheadServiceContact value={value} data={data} column={column} onChanged={onChange} onBlur={FieldMatchingService}/>
    {
      id: 'contactSource',
      field: 'contactSource',
      label: 'Contact Source',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      headerCellRenderer: ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => (<span onChange={onChange} required>Contact Source<span id="red_color">&#42;</span></span>),
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={contactSource} />


    },

    {
      id: 'contactPositionLevel',
      field: 'contactPositionLevel',
      label: 'Persona Level',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={personaLevels} />

    },


    {
      id: 'contactDepartment',
      field: 'contactDepartment',
      label: 'Department',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={departments} />

    },

    {
      id: 'contactTitle',
      field: 'contactTitle',
      label: 'Title',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />


    },
    {
      id: 'contactPhone',
      field: 'contactPhone',
      label: 'Phone',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />

    },
    {
      id: 'contactMobile',
      field: 'contactMobile',
      label: 'Mobile',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />


    },

    {
      id: 'contactCountry',
      field: 'contactCountry',
      label: 'Mailing Country',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={countriesList} />

    },
    {
      id: 'contactState',
      field: 'contactState',
      label: 'Mailing State',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />

      // editorCellRenderer: ({
      //     tableManager,
      //     value,
      //     data,
      //     column,
      //     colIndex,
      //     rowIndex,
      //     onChange
      //   }) => <DropdownWithMeta propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={statesListByCountry} parent={"contactCountry"} state={true} />

    },
    {
      id: 'contactCity',
      field: 'contactCity',
      label: 'Mailing City',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />

      // editorCellRenderer: ({
      //     tableManager,
      //     value,
      //     data,
      //     column,
      //     colIndex,
      //     rowIndex,
      //     onChange
      //   }) => <DropdownWithMeta propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={[]} parent={"contactState"} city={true} />
    },

    {
      id: 'crmPersonaCode',
      field: 'crmPersonaCode',
      label: 'Persona code',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={personaCodes} />

    },
    {
      id: 'contactRole',
      field: 'contactRole',
      label: 'Contact Role',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={contactRoles} />


    },


    {
      id: 'contactLinkedin',
      field: 'contactLinkedin',
      label: 'Contact Linkedin URL',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not a valid website" placeholder="example.com" />
    },
    /*{
      id: 'contactSecondaryEmail',
      field: 'contactSecondaryEmail',
      label: 'Other Email ID',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <TypeAheadServiceContact value={value} data={data} column={column} onChanged={onChange} validator={validateEmail} message=" not a valid email"/>

    },*/

    {
      id: 'contactSecondaryNumber',
      field: 'contactSecondaryNumber',
      label: 'Other Number',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />


    },
    {
      id: 'crmContactGeography',
      field: 'crmContactGeography',
      label: 'Geography',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={capillaryGeography} />
      // }) => <DropdownWithMeta propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={capillaryGeography} tab="contact"/>


    },
    {
      id: 'contactRegion',
      field: 'contactRegion',
      label: 'Region',
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="CONTACT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={crmContactRegionsList} level="1" parent="crmContactGeography" />


      // }) => <DropdownWithMeta propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={crmContactRegionsList} level="1" parent="crmContactGeography" tab="contact"/>
    },

    

    {
      id: 'buttons',
      width: 'max-content',
      visible: true,
      pinned: true,
      sortable: false,
      resizable: false,
      cellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex }) => (
        <div style={styles.buttonsCellContainer}>
          <button
            title="Edit"
            style={styles.editButton}
            onClick={e => { e.stopPropagation(); tableManager.rowEditApi.setEditRowId(data.id) }}
          >
            {EDIT_SVG}
          </button>
          <button
            id={rowIndex}
            title="Delete"
            style={styles.deleteButton}
            onClick={
              e => {
                deleteSingleRow(e, rowIndex, [...tableManager.rowsApi.rows])
                suggestionSelected = false;
              }
            }
          >
            {TRASH_ICON}
          </button>
        </div>
      ),
      editorCellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex, onChange, rowsClone }) => (
        <div style={styles.buttonsCellEditorContainer}>
          <button
            title="Cancel"
            style={styles.cancelButton}
            onClick={e => {
              e.stopPropagation();
              const newData={};
              for(const key in data) 
              { if(key!=="id") newData[key]=""}
              newData.id=data.id;
              onChange({...newData});
            }}

          >
            {CANCEL_SVG}
          </button>
          <button
            title="Save"
            style={styles.saveButton}
            onClick={(e) => {
              e.stopPropagation();
              let rowsClone = [...tableManager.rowsApi.rows];
              let updatedRowIndex = rowsClone.findIndex(r => r.id === data.id);

              console.log("while saving data rowsclone", rowsClone)
              console.log("while saving data rowsclone[index]", rowsClone[updatedRowIndex])
              rowsClone[updatedRowIndex] = data;
              console.log("while saving data rowsclone after", rowsClone)
              console.log("while saving data rowsclone[index] after", rowsClone[updatedRowIndex])


              let [mandatoryFieldsError, validationFieldErrorList] = check_mandatory_validate_contactFields(rowsClone[updatedRowIndex])

              if (mandatoryFieldsError) {

                console.log("mandatory fields error", mandatoryFieldsError)

                toast.error(<Alert severity="error">{mandatoryFieldsError}</Alert>,{
                  closeOnClick: true,
                  autoClose: true,
                  closeButton: true,
                  newestOnTop: true,
                });
                return;
              }

              if (validationFieldErrorList.length > 0) {
                console.log("validationFieldErrorList size", validationFieldErrorList.length)
                for (let i = 0; i < validationFieldErrorList.length; i++) {

                  console.log("validationFieldErrorList", validationFieldErrorList[i])
                  
                toast.error(<Alert severity="error">{validationFieldErrorList[i]}</Alert>,{
                  closeOnClick: true,
                  autoClose: true,
                  closeButton: true,
                  newestOnTop: true,
                });
                }
                return;
              }
              console.log("while saving data rowsclone  2", rowsClone)
              console.log("while saving data rowsclone[index] after 2", rowsClone[updatedRowIndex])

              if (checkEmail(rowsClone, updatedRowIndex)) {
            
                toast.error(<Alert severity="error">Entered record is already present in the grid</Alert>,{
                  closeOnClick: true,
                  autoClose: true,
                  closeButton: true,
                  newestOnTop: true,
                });

              }

              else {
                console.log("rowinx " + updatedRowIndex);
                // tableManager.rowEditApi.setEditRowId(data.id+1);
                console.log(rowsClone);
                save_data(rowsClone, updatedRowIndex)
                  .then((r) => {
                    console.log(rowsClone[0])
                    if ((rowsClone[0].companyName !== "") && (rowsClone[0].companyWebsite !== "") && (rowsClone[0].accountId !== "")) {
                      // setInitialOptions()
                      addNewRow(rowsClone, data.id)

                      console.log([...tableManager.rowsApi.rows][1]);
                      tableManager.rowEditApi.setEditRowId(rowsClone[0].id);
                    }
                  })
                  .catch((r) => {
                    alert("ERROR")

                    tableManager.rowEditApi.setEditRowId(rowsClone[0].id);

                    ;
                  })
                //  .catch((r) => {alert("ERROR"); rowsClone.splice(0,1);setRowsData(rowsClone);   addNewRow(rowsClone,data.id); tableManager.rowEditApi.setEditRowId(rowsClone[0].id);})

              }




              //  if (check_mandatory_contactFields(rowsClone, updatedRowIndex) )
              //  {

              //   if(checkEmail(rowsClone, updatedRowIndex))

              //   {
              //     toast.error("This record seems to be already present in the grid", {
              //       className: "custom-toast",
              //       closeOnClick: false,
              //       toastId: "my_toast",
              //       autoClose: true,
              //       closeButton: true,




              //     });//alert("Entered Company Website is already present in the grid.")


              //         console.log("Already present");
              //   }  

              //   else
              //    {
              //      console.log("rowinx" + updatedRowIndex);
              //   // tableManager.rowEditApi.setEditRowId(data.id+1);
              //    console.log(rowsClone);
              //     save_data(rowsClone, updatedRowIndex)
              //     .then((r) => {

              //      if((rowsClone[0].companyName!== "") && (rowsClone[0].companyWebsite !== "") )
              //      {

              // addNewRow(rowsClone,data.id)

              // console.log([...tableManager.rowsApi.rows][1]);
              // tableManager.rowEditApi.setEditRowId(rowsClone[0].id);
              // }
              //     })
              //    .catch((r) => {alert("ERROR"); rowsClone.splice(0,1);setRowsData(rowsClone);   addNewRow(rowsClone,data.id); tableManager.rowEditApi.setEditRowId(rowsClone[0].id);})

              //  }
              //  }
              //  else {
              //  tableManager.rowEditApi.setEditRowId(data.id);

              //  }

            }}
          >
            {SAVE_SVG}
          </button>
        </div>
      ),
    },

  ];

};


export default GetColumns;
