import React, { useState, useEffect } from "react";
import "./App.css";
import ReactDOM from "react-dom";
//import "./styles.css";
//import "./index.css";
import "./gridindex.css";
import {API_BASE_URL,META_BASE_URL, LOGIN_URL, AUTH_URL} from './constants';
import { MyAwesomeTable } from "./ContactCreation";
import {contactDownload} from "./ContactCreation";
import   {MyAwesomeTable2}  from "./AccountCreation";
import { download  }from "./AccountCreation";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useClearCache } from 'react-clear-cache';
import customTheme from "@bamboobox/b2-theme";

import {
  FormGroup,
  Link,
  Switch,
  Box,
  Tabs,
  Tab,
  Typography,
  Button, 
  IconButton
} from "@material-ui/core/";
import {useAuthState, AuthProvider} from "@bamboobox/b2logincheck";
//new changes

import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import { version } from '../package.json';
import CacheBuster from 'react-cache-buster';

console.log("verison of build", version);
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

let selectedValue = 0;


function BasicTabs(props) {

  const [value, setValue] = React.useState(0);
  let [uploadButtonClicked , setUploadButtonClicked] = useState(false);
  let [selectedFileData , setSelectedFileData] = useState();
  let [isPicked , setIsPicked]  = useState(false);
  let [selectFileName, setSelectFileName] = useState()
  //let [count , setCount] = useState(0);
  const ref = React.createRef();

  const  selectFile = (event) => {
    //console.log("this is event target",event.target.files[0]);
    setSelectedFileData({selectedFile : event.target.files[0] });
    setIsPicked(true);
    setSelectFileName(event.target.files[0].name);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    selectedValue = newValue;
    if(newValue){
      setUploadButtonClicked(false);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style ={{display :"flex" , justifyContent :"space-between"}}>
        <div>
          <Tabs  value={value} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{
            style:{
              backgroundColor:"#21DA8C",
              height:"6px",
              borderRadius:"3px"
            }
          }}>
            <Tab label="ADD CONTACTS" {...a11yProps(0)} />
            <Tab label="ADD ACCOUNTS" {...a11yProps(1)} />
          </Tabs>
        </div>
        <div style={{paddingTop :"6px"}}>
          <label for ="upload-file" style={{color:"#21DA8C",textDecorationLine: "underline", fontSize:"12px", fontWeight:800, letterSpacing:"0.08em"}}>Select File</label>
          <input
              id = "upload-file"
              type="file"
              accept=".csv, text/plain, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange = {selectFile}
              ref={ref}
            />
            <Tooltip title="Template Download">
            <IconButton
            style={{paddingRight :"10px"}}
            
            color="primary"
            onClick={() =>{
              if(selectedValue == 1 ){
                download();
              }else {
                contactDownload();
              }
            }}
          >
            {TEMPLATE_DOWNLOAD_SVG}
          </IconButton>
            </Tooltip>
          
          <Button
            title="Upload the selected file"
            variant="contained"
            style={{backgroundColor :"#21DA8C", color :"white" , borderRadius: "18px", height:"32px", width:"86px", fontSize:"12px", fontWeight:800,letterSpacing: "0.08em"}}
            onClick={() =>{
              setUploadButtonClicked(true)
            }}
            className="btn"
          >
            Upload
          </Button>
        </div>
      </Box>
      <TabPanel value={value} index={0}>
          <MyAwesomeTable enrichmentButtonClicked = {props.enrichmentButtonClicked} setEnrichmentButtonClicked ={props.setEnrichmentButtonClicked} uploadButtonClicked = {uploadButtonClicked } setUploadButtonClicked ={setUploadButtonClicked} selectedFileData = {selectedFileData} isPicked = {isPicked} />
      </TabPanel>
      <TabPanel value={value} index={1}>
          <MyAwesomeTable2 enrichmentButtonClicked = {props.enrichmentButtonClicked} setEnrichmentButtonClicked ={props.setEnrichmentButtonClicked} uploadButtonClicked ={uploadButtonClicked } setUploadButtonClicked = {setUploadButtonClicked} selectedFileData = {selectedFileData} isPicked = {isPicked}/>
      </TabPanel>
    </Box>
  );
}

const ENRICHMENT_QUEUE_SVG = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.7979 5.96298H9.95042C9.7749 5.96298 9.6326 6.10528 9.6326 6.2808V10.1449C9.6326 10.35 9.46568 10.5169 9.26058 10.5169H1.85505C1.64999 10.5169 1.4832 10.35 1.4832 10.1449V2.73951C1.4832 2.53436 1.64999 2.36744 1.85505 2.36744H5.91701C6.09254 2.36744 6.23484 2.22514 6.23484 2.04962V1.2021C6.23484 1.02658 6.09254 0.884277 5.91701 0.884277H1.85505C0.832171 0.884277 3.05176e-05 1.71655 3.05176e-05 2.73951V10.145C3.05176e-05 11.1679 0.832214 12.0001 1.85505 12.0001H9.26054C10.2835 12.0001 11.1157 11.1679 11.1157 10.145V6.28084C11.1158 6.10528 10.9735 5.96298 10.7979 5.96298Z" fill="#21DA8C"/>
  <path d="M11.6821 0H8.31099C8.13547 0 7.99317 0.142299 7.99317 0.317821V1.16534C7.99317 1.34087 8.13547 1.48317 8.31099 1.48317H9.46803L5.11409 5.83702C4.98997 5.96114 4.98997 6.16234 5.11409 6.28651L5.71337 6.88583C5.77299 6.94545 5.8538 6.97893 5.93813 6.97893C6.02242 6.97893 6.10327 6.94545 6.16285 6.88583L10.5168 2.53189V3.68889C10.5168 3.86441 10.6591 4.00671 10.8346 4.00671H11.6821C11.8577 4.00671 12 3.86441 12 3.68889V0.317821C12 0.142299 11.8577 0 11.6821 0Z" fill="#21DA8C"/>
</svg>


)

const TEMPLATE_DOWNLOAD_SVG = (
<svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="1.13525" width="6.09309" height="5.07758" fill="#BDBDBD"/>
<rect x="8.12411" y="1.13525" width="6.09309" height="5.07758" fill="#BDBDBD"/>
<rect y="8.24414" width="6.09309" height="5.07758" fill="#BDBDBD"/>
<rect x="8.12411" y="8.24414" width="6.09309" height="5.07758" fill="#BDBDBD"/>
<rect y="15.3525" width="6.09309" height="5.07758" fill="#BDBDBD"/>
<rect x="8.12411" y="15.3525" width="6.09309" height="5.07758" fill="#BDBDBD"/>
<g clip-path="url(#clip0_1896_22772)">
<path d="M17.6022 21.4449L18.9563 21.4449L16.2482 24.1529L13.5402 21.4449L14.8942 21.4449L14.8942 18.7368L17.6022 18.7368L17.6022 21.4449Z" fill="#BDBDBD"/>
</g>
<defs>
<clipPath id="clip0_1896_22772">
<rect width="7.10861" height="6.09309" fill="white" transform="translate(19.2948 17.8911) rotate(90)"/>
</clipPath>
</defs>
</svg>

);

function App() {
  let [enrichmentButtonClicked , setEnrichmentButtonClicked] = useState(false);
  // let [uploadButtonClicked , setUploadButtonClicked] = useState(false);
  // let [selectedFileData , setSelectedFileData] = useState();
  // let [isPicked , setIsPicked]  = useState(false)
  // //let [count , setCount] = useState(0);
  // const ref = React.createRef();

  // const  selectFile = (event) => {
  //   //console.log("this is event target",event.target.files[0]);
  //   setSelectedFileData({selectedFile : event.target.files[0] });
  //   setIsPicked(true);
  // }

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  
  useEffect(() => {
    console.log("latest version",isLatestVersion);
    if(!isLatestVersion) {
        // e.preventDefault();
        emptyCacheStorage();
        window.location.reload(true);
          
    }
}, [isLatestVersion])
  
  return (
    <div>
      <ThemeProvider theme={customTheme}>
      <div className="demo">
        <div style={{display :"flex" ,justifyContent :"space-between" }}>
          <div>
              <Typography className="page-heading" style={{fontSize:"20px",color:"#595959",fontWeight:700}}>
              Account  &amp; Contact Creation
              </Typography>
          </div>
          <div style={{ paddingTop :"5px"}}>
          <Link
            component="button"
            variant="body2"
            onClick={() => {
                setEnrichmentButtonClicked(true)
            }}
            style={{color :"#21DA8C", fontSize:"12px",fontWeight:700,}}
          >
            <div style={{display:"flex"}}>
              <div style={{textDecorationLine: "underline", paddingRight:"8px", fontSize:"12px", fontWeight:700,letterSpacing:"0.08em"}}>
                ENRICHMENT QUEUE
              </div>
              <div style={{paddingTop:"1px"}}>
                {ENRICHMENT_QUEUE_SVG}
              </div>
            </div>
          </Link>
          </div>
        </div>
        <div className="button-array" style={{marginLeft : "70%" , display : "flex"}} >
          {/* <input
            type="file"
            accept=".csv, text/plain, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange = {selectFile}
            ref={ref}
          /> */}
          {/* <IconButton
            title="Template Download"
            color="primary"
            onClick={() =>{
              if(selectedValue == 1 ){
                download();
              }else {
                contactDownload();
              }
            }}
          >
            {TEMPLATE_DOWNLOAD_SVG}
          </IconButton> */}
          {/* <Button
            title="Upload the selected file"
            variant="contained"
            color="primary"
            onClick={() =>{
              setUploadButtonClicked(true)
            }}
            className="btn"
          >
            Upload
          </Button> */}
        </div>
        {/* <BasicTabs enrichmentButtonClicked = {enrichmentButtonClicked} setEnrichmentButtonClicked={setEnrichmentButtonClicked} uploadButtonClicked ={uploadButtonClicked} setUploadButtonClicked = {setUploadButtonClicked} selectedFileData ={selectedFileData} isPicked = {isPicked}/> */}
        <BasicTabs enrichmentButtonClicked = {enrichmentButtonClicked} setEnrichmentButtonClicked={setEnrichmentButtonClicked} />

      </div>
    </ThemeProvider>
    </div>
    
  );
}

export default App;

ReactDOM.render(<AuthProvider loginURL={LOGIN_URL} authcheckURL={AUTH_URL}>
  <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={true}>
  <App />
  </CacheBuster>
  </AuthProvider>, document.getElementById("root"));
