import React, { useState, useEffect, useRef } from "react";
// import GridTable from "@nadavshaar/react-grid-table";
import GridTable from './grid_src';
import Paper from '@mui/material/Paper';
import GetColumns from "./getAccountColumns";
import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, createMuiTheme } from "@material-ui/core/";
import {useColumnVisibilityPersist} from "@bamboobox/b2gridutils";
// import "./index.css";
import "./gridindex.css";
// import "./styles.css";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { SvgIcon } from "@material-ui/core";
import upload from "./upload.svg";
import "./AccountCreation.css";
import data from "./data.json";
import {useAuthState, AuthProvider} from "@bamboobox/b2logincheck";
 import {myConstClass} from "./constants.js";
 import loadMetaData, {createHierarchy} from './LoadMetaData.js';
import './../src/grid_src/styleNew.css'
// import "@bamboobox/b2-theme/grid/style.css";  Commented out because of table height
// import { ViewColumn } from "@material-ui/icons";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import './toast.css';
import Alert from '@mui/material/Alert';

//const localhost = window._env_.API_DOMAIN;

const localhost = myConstClass.localhost;

toast.configure({
  autoClose:6000,
   draggable: true,
   hideProgressBar: true,
   position: toast.POSITION.BOTTOM_LEFT,
   
     
});

const ADD_ENRICH_SVG = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.42629 11.968C9.4433 10.9499 10.2952 10.1429 11.3134 10.1429H12.6446V6.8221H15.9654V5.49097C15.9654 4.4727 16.7724 3.6208 17.7905 3.60378C18.8306 3.58642 19.6791 4.42431 19.6791 5.46038V6.8221H21.7109C22.4229 6.8221 23 7.39921 23 8.11116V15.8884C23 16.6004 22.4229 17.1775 21.7109 17.1775H19.6792V14.3824C19.6792 13.3641 18.8722 12.5122 17.8541 12.4952C16.814 12.4779 15.9655 13.3158 15.9655 14.3518V17.1774H12.6447V13.8566H11.2829C10.2468 13.8567 9.40893 13.0081 9.42629 11.968Z"
      fill="#FFFFFF"
    />
    <path
      d="M2.28914 6.82031H11.3554V8.85205H11.3136C10.4844 8.85205 9.69881 9.16826 9.10133 9.7424C8.49375 10.3262 8.15146 11.1084 8.13754 11.9447C8.12331 12.7953 8.44382 13.5976 9.03997 14.2038C9.6362 14.81 10.4328 15.1439 11.283 15.1439H11.3555V17.1757H8.03467V18.5374C8.03467 19.5734 7.18612 20.4113 6.14606 20.394C5.12792 20.377 4.32092 19.5251 4.32092 18.5068V17.1757H2.28914C1.57719 17.1757 1.00008 16.5986 1.00008 15.8866V8.10938C1.00008 7.39747 1.57719 6.82031 2.28914 6.82031Z"
      fill="#FFFFFF"
    />
  </svg>
);

const TEMPLATE_DOWNLOAD_SVG = (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 13V18H1V13H7ZM14 13V18H8V13H14ZM14 7V12H8V7H14ZM14 1V6H8V1H14ZM7 7V12H1V7H7ZM7 6H1V1H7V6ZM19.4286 19.1538H22L17.5 24L13 19.1538H15.5714V15H19.4286V19.1538Z"
      fill="#109CF1"
      fill-opacity="0.54"
    />
  </svg>
);

var obj1 = {
  id: 1,
  row_id: 1,
  msg: "",
  accountSource: "Manual",
  accountId: "",
  companyName: "",
  companyGroupName: "",
  companyWebsite: "",
  companyParentWebsite: "",
  companyGroupWebsite: "",
  companyPrimaryIndustryCategory: "",
  companySubIndustryCategory: "",
  companyBrandRevenue: "",
  companyEmployeeCount: "",
  companyFax: "",
  companyPhone: "",
  companyStreet: "",
  companyCity: "",
  companyState: "",
  companyCountry: "",
  companyContinent: "",
  companyZipCode: "",
  companyDescription: "",
  companyLinkedin: "",
  companyFacebook: "",
  companyTwitter: "",
  companyParent: "",
  companyHierarchy: "",
  companyLocationCount: "",
  companyProducts: "",
  companyType: "",
  companyClassification: "",
  companyTechAttributes: "",
  companyHashTag: "",

  companyRecentFundingAmount: "",
  companyRecentFundingDate: "",
  companyTotalFundingAmount: "",
  companyFundingList: "",

  companyAccountRegion: "",
  crmFmExecutive: "",
  crmNumberofStores: "",
  crmBusinessFormat: "",
  crmISExecutive: "",
  crmFSExecutive: "",
};
const MyAwesomeTable2 = (props) => {
  
  const [allMetadata, setAllMetaData] = useState([]);
  const [tenantField, setTenantField] = useState({});
  const [metadata, setMetaData] = useState([]);
 
  const [stateMetaData, setStateMetataData] = useState([]);
  const [fsExecdata, setfsExecMetaData] = useState([]);
  const [isExecMetaData, setIsExecMetataData] = useState([]);
  const [columVisibilityTransformer, onColumnChangeSave] = useColumnVisibilityPersist("B2AMAccountCreation");
  const [tableManager, setTableManager] = useState(null);
  const [rowsData, setRowsData] = useState([obj1]);
  const [isLoading, setLoading] = useState(false);
  const [editRowId, setEditRowId] = useState(1);
  let [searchText, setSearchText] = useState("");
  let [selectedRowsIds, setSelectedRowsIds] = useState([]);
  let [sort, setSort] = useState({ colId: null, isAsc: true });
  let [page, setPage] = useState(1);
  let [pageSize, setPageSize] = useState(15);
  let [pageSizes, setPageSizes] = useState([15, 50, 75, 100]);
  let [enableColumnsReorder, setEnableColumnsReorder] = useState(true);
  let [highlightSearch, setHighlightSearch] = useState(false);
  let [showSearch, setShowSearch] = useState(false);
  let [showRowsInformation, setShowRowsInformation] = useState(true);
  let [showColumnVisibilityManager, setShowColumnVisibilityManager] = useState(
    true
  );
  let [isHeaderSticky, setIsHeaderSticky] = useState(true);
  let [isVirtualScroll, setIsVirtualScroll] = useState(true);
  let [isPaginated, setIsPaginated] = useState(true);
  let [minSearchChars, setMinSearchChars] = useState(2);
  let [minColumnResizeWidth, setMinColumnWidth] = useState(70);
  let [columns, setColumns] = useState(columVisibilityTransformer(GetColumns({ setRowsData, metadata, stateMetaData, fsExecdata, isExecMetaData, allMetadata })));
  let [isSettingsOpen, setIsSettingsOpen] = useState(false);
  let [selectAllMode, setSelectAllMode] = useState("page");
  const [state, setState] = React.useState({
    checkedC: true,
  });
  const ref = useRef();
  const userData = useAuthState();
  let tenantId = userData.user.tenantId;
  let userId = userData.user.userUuid;

  // const [state, setState] = useState();
 //
 
 //localStorage.getItem(showColumnVisibilityManager);
  var adapt = {
    tenantId:tenantId,// "TTT-ID-00000",
    userId:userId ,// "USR-ID-0000",
    error: "",
    rows: [],
  };

 // localStorage.setItem('getColumns',JSON.stringify(getColumns))
  

  // function accountFromRow(row) {

  //      var account =
  //      {

  //             "accountId": row.accountId,

  //      }
  //      return account;
  //  }

  // function fundingFromRow(row) {

  //      var funding = {
  //          "companyRecentFundingAmount": row.companyRecentFundingAmount,
  //          "companyRecentFundingDate": row.companyRecentFundingDate,
  //          "companyTotalFundingAmount": row.companyTotalFundingAmount,
  //          "companyFundingList": row.companyFundingList
  //      }

  //      return funding;

  //  }

  // function crmfromRow(row)
  // {

  //     var crm = {

  //             "crmAccountRegion": row.crmAccountRegion,
  //             "crmFmExecutive": row.crmFmExecutive,
  //             "crmNumberofStores": null,
  //             "crmBusinessFormat": row.crmBusinessFormat,
  //             "crmISExecutive": row.crmISExecutive,
  //             "crmFSExecutive": row.crmFSExecutive

  // }

  //      return crm;

  // }
useEffect(() => {
  // loadMetaData(setMetaData, setStateMetataData, setIsExecMetataData, setfsExecMetaData ,tenantId);
  createHierarchy(tenantId, setAllMetaData, setTenantField)
  // setLoading(true);
  // setTimeout(() => {
  //   setRowsData(rowsData);
  //   // setRowsData(data);
  //   // setLoading(false);
  // }, 1500);
}, []);

  const addToListView = () => {
  };

  //ENRICHMENT GRID
  const addToEnrichQueue = () => {
    var adapt = {
      tenantId:tenantId,// "TTT-ID-00000",
      userId:userId ,// "USR-ID-0000",
      error: "",
      rows: [],
    };
    if (selectedRowsIds.length === 0) {

      toast.error(<Alert severity="error">No row is selected to be added to the Enrichment Queue</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true,
      });
    }
    for (let i = 0; i < selectedRowsIds.length; i++) {
      for (let j = 0; j < rowsData.length; j++) {
        if (selectedRowsIds[i] === undefined) {
          continue;
        }
        if (selectedRowsIds[i] == rowsData[j].id) {
          var json = { id: rowsData[j].accountId };
          adapt.rows.push(json);
          //   mcarlson@vmware.com
          // console.log(adapt);
          break;
        }
      }
    }

    axios.post(localhost + "/b2pm/account/add", adapt,{withCredentials:true}).then((tabledata) => {
      if (tabledata.status === 200 && selectedRowsIds.length !== 0) {
        //  SNACKBAR TOAST
        for (let i = 0; i < tabledata.data.rows.length; i++) {
          if (
            tabledata.data.rows[i].id === "" ||
            tabledata.data.rows[i].msg === "ERROR"
          ) {

            toast.error(<Alert severity="error">Unsaved/Empty Row cannot be added to Enrichment Queue</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true,
            });
            
            continue;
          } else {
            
            toast.success(<Alert severity="success">Added to Enrichment Queue successfully</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true,
            });
          }
        }
      }
    });
  };

  if (props.enrichmentButtonClicked){
    //alert("inside account")
    addToEnrichQueue();
    props.setEnrichmentButtonClicked(false);
  }

  // var adapt1 = {
  //   tenantID: "TTT-ID-00000",
  //   userID: "USR-ID-0000",
  // };
  function unselectfile(event) {
    ref.current.value = "";
  }

  let [uploadstate, uploadSetState] = useState();
  let [isPicked, setIsPicked] = useState(false);

 
  // function selectfile(file) {
  //   uploadSetState({ selectedFile:file });
  //   setIsPicked(true);
  // }

  let counter = rowsData.length;

  function getAllrows(inp_rows) {
    // console.log(inp_rows)

    var rows = inp_rows.data.rowSelected;
    var rows_out = [];

    for (let i = 0; i < rows.length; i++) {
      // console.log(rowsData.length);
      rows_out.push(getAllRowsData(rows[i], counter));

      counter++;
    }

    //console.log(rows_out);

    return rows_out;
  }

  function getAllRowsData(inp, i) {
    var row = {
      id: counter,
      row_id: counter,
      ...inp.account,
      ...inp.funding,
      ...inp.crm,
    };

    return row;
  }

  function upload() {
   props.setUploadButtonClicked(false);
   uploadstate = props.selectedFileData;
   isPicked = props.isPicked
   //selectfile(props.selectedFileData.fileData);
    if (isPicked === false) {

      toast.error(<Alert severity="error">Please select a file to upload</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true,
      });
      return;
    }

    if (
      uploadstate.selectedFile.type.toLowerCase() === ".csv" ||
      uploadstate.selectedFile.type.toLowerCase() === "text/plain" ||
      uploadstate.selectedFile.type.toLowerCase() ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      uploadstate.selectedFile.type.toLowerCase() ===
        "application/vnd.ms-excel" ||
      uploadstate.selectedFile.type.toLowerCase() === "text/csv"
    ) {
      // Create an object of formData
      const formData = new FormData();
      const FileDownload = require("js-file-download");

      // Update the formData object
      formData.append(
        "file",
        uploadstate.selectedFile,
        uploadstate.selectedFile.name
      );
      formData.append("userID", userId);
      formData.append("tenantID", tenantId);

      // console.log(uploadstate.selectedFile);

      // Request made to the backend api
      // Send formData object

      axios.post(localhost + "/b2pm/account/uploadfile",formData,{withCredentials:true})
        .then((response) => {
          if (response.status === 200) {
            let datalen = response.data.rowSelected.length;
            // console.log(datalen);
            let datacount = rowsData.length + datalen;
            //console.log(datacount)
            var obj = {
              id: datacount,
              row_id: datacount,
              msg: "",
              accountSource: "Manual",
              accountId: "",
              companyName: "",
              companyGroupName: "",
              companyWebsite: "",
              companyParentWebsite: "",
              companyGroupWebsite: "",
              companyPrimaryIndustryCategory: "",
              companySubIndustryCategory: "",
              companyBrandRevenue: "",
              companyEmployeeCount: "",
              companyFax: "",
              companyPhone: "",
              companyStreet: "",
              companyCity: "",
              companyState: "",
              companyCountry: "",
              companyContinent: "",
              companyZipCode: "",
              companyDescription: "",
              companyLinkedin: "",
              companyFacebook: "",
              companyTwitter: "",
              companyParent: "",
              companyHierarchy: "",
              companyLocationCount: "",
              companyProducts: "",
              companyType: "",
              companyClassification: "",
              companyTechAttributes: "",
              companyHashTag: "",
              companyRecentFundingAmount: "",
              companyRecentFundingDate: "",
              companyTotalFundingAmount: "",
              companyFundingList: "",

              crmAccountRegion: "",
              crmFmExecutive: "",
              crmNumberofStores: "",
              crmBusinessFormat: "",
              crmISExecutive: "",
              crmFSExecutive: "",
            };

            var data1 = rowsData;
            data1.splice(0, 1);
            var data2 = getAllrows(response);

            const data3 = [obj, ...data2, ...data1];

            setRowsData([...data3]);
            setEditRowId(datacount);
            setIsPicked(false);
            // gridData.current = tabledata;
            let str = response.data.msg;
            let res = str.split("<&>");

            if (response.status === 200) {
              //  SNACKBAR TOAST
              unselectfile();
              toast(<Alert>{res[0]}</Alert>,{
                closeOnClick: true,
                autoClose: true,
                closeButton: true,
                newestOnTop: true,
              });
            }

            //console.log(rowsData.length);
            var d = new Date();
            d = new Date(d.getTime());
            var date_format_str =
              d.getFullYear().toString() +
              ((d.getMonth() + 1).toString().length == 2
                ? (d.getMonth() + 1).toString()
                : "0" + (d.getMonth() + 1).toString()) +
              (d.getDate().toString().length == 2
                ? d.getDate().toString()
                : "0" + d.getDate().toString()) +
              (d.getHours().toString().length == 2
                ? d.getHours().toString()
                : "0" + d.getHours().toString()) +
              ((parseInt(d.getMinutes() / 5) * 5).toString().length == 2
                ? (parseInt(d.getMinutes() / 5) * 5).toString()
                : "0" + (parseInt(d.getMinutes() / 5) * 5).toString()) +
              "00";
            //console.log(date_format_str);

            axios.get(localhost + "/b2pm/download/" + res[1] + "/"+ tenantId,{withCredentials:true})
              .then((response) => {
                FileDownload(response.data, "load_results_" + date_format_str+ ".csv");
              });
          }
        });
      //endif
    } else {

      toast.error(<Alert severity="error">File format must be in CSV to upload</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true,
      });
      unselectfile();
    }
  }
 
  if (props.uploadButtonClicked){
    //alert("inside upload account")
    upload();
  }

  const IconButton = withStyles((theme) => ({
    root: {
      ...theme.typography.button,
      boxSizing: "border-box",
      minWidth: 24,
      padding: "6px 6px",
    },
  }))(Button);

  // const download = () => {
  //   axios.get(localhost + "/b2pm/templateFile/Template_v2_accounts.csv",{withCredentials:true})
  //     .then(function (response) {
  //       // this.setState({response})

  //       const FileDownload = require("js-file-download");
  //       // let headerLines = response.request.getResponseHeader(
  //       //   "Content-Disposition"
  //       // );
  //       // console.log(
  //       //   "content-dispostion" +
  //       //     response.request.getResponseHeader("Content-Disposition")
  //       // );

  //       FileDownload(response.data, "Template_v2_accounts.csv");
  //     });
  // };

  return (
    <div className="App">
      <div className="button-section">
        <div className="button-array">
          {/* <input
            type="file"
            accept=".csv, text/plain, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            //onChange={selectfile}
            ref={ref}
          /> */}
          {/* <IconButton
            title="Template Download"
            color="primary"
            onClick={() => download()}
          >
            {TEMPLATE_DOWNLOAD_SVG}
          </IconButton> */}
          {/* <Button
            title="Upload the selected file"
            variant="contained"
            color="primary"
            //onClick={() => upload()}
            className="btn"
          >
            Upload
          </Button> */}
        </div>

        {/* <Button
          title="Add to Enrichment Queue"
          variant="contained"
          color="primary"
          onClick={() => addToEnrichQueue()}
          className="btn"
          startIcon={ADD_ENRICH_SVG}
        >
          Enrichment Queue{" "}
        </Button> */}
      </div>
      <Paper style={{ borderRadius: '18px', padding: `28px 28px 0px`, boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`, background: `#FFFFFF`, }}>
      <GridTable
         columns={columns}
         rows={rowsData}
         isLoading={isLoading}
         editRowId={editRowId}
         onEditRowIdChange={setEditRowId}
         selectedRowsIds={selectedRowsIds}
         onSelectedRowsChange={setSelectedRowsIds}
         onColumnsChange={onColumnChangeSave(setColumns)}
         onRowClick={({ rowIndex, data, column, isEdit, event }, tableManager) =>
           !isEdit &&
           tableManager.rowSelectionApi.getIsRowSelectable(data.id) &&
           tableManager.rowSelectionApi.toggleRowSelection(data.id)
         }
         onLoad={setTableManager}
         searchText={searchText}
         onSearchTextChange={setSearchText}
         sort={sort}
         onSortChange={setSort}
         page={page}
         onPageChange={setPage}
         pageSize={pageSize}
         onPageSizeChange={setPageSize}
         pageSizes={pageSizes}
         enableColumnsReorder={enableColumnsReorder}
         highlightSearch={highlightSearch}
         showSearch={showSearch}
         showRowsInformation={showRowsInformation}
         showColumnVisibilityManager={showColumnVisibilityManager}
         isHeaderSticky={isHeaderSticky}
         isVirtualScroll={isVirtualScroll}
         isPaginated={isPaginated}
         minSearchChars={minSearchChars}
         minColumnResizeWidth={minColumnResizeWidth}
         selectAllMode={selectAllMode}
         icons={{ columnVisibility: <MoreHorizIcon color="action" /> }}
      />
      </Paper>
    </div>
  );
};

const download = ()=> {
  axios.get(localhost + "/b2pm/templateFile/Template_v2_accounts.csv",{withCredentials:true})
    .then(function (response) {
      // this.setState({response})

      const FileDownload = require("js-file-download");
      // let headerLines = response.request.getResponseHeader(
      //   "Content-Disposition"
      // );
      // console.log(
      //   "content-dispostion" +
      //     response.request.getResponseHeader("Content-Disposition")
      // );

      FileDownload(response.data, "Template_v2_accounts.csv");
    });
};





export  { MyAwesomeTable2 , download } ;
