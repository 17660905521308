import React, { useEffect, useState } from "react";
import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {myConstClass} from "./constants.js";
import {useAuthState} from "@bamboobox/b2logincheck";
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import NameData from "./firstname.json"; 
import {getAllRowsData, getAllRowsDatawithId, saveAccountfromRow, saveFundingfromRow, saveCRMfromRow} from './functionRepositoryDataSaveGet';
import './toast.css';
import Alert from '@mui/material/Alert';

const localhost = myConstClass.localhost; 

toast.configure({
    autoClose:6000,
     draggable: true,
     hideProgressBar: true,
     position: toast.POSITION.BOTTOM_LEFT,
     
       
  });
  console.log("NameData",NameData);


function FieldMatchingService(props){
    const userData = useAuthState();
    const [options, setOptions] = useState([]);
    let tenantId = userData.user.tenantId;
    let userId = userData.user.userUuid;
    let value=props.value
    let onChange=props.onChanged
    let data=props.data
    let column=props.column;
    let optionsFullSuggestions=[]

    // useEffect(() => {
        
    //       console.log("options",options)
    //   },[options]);
//    let url ="/b2pm/account/autocomplete/name";
//    if(column=="companyWebsite")
//    {
//      url ="/b2pm/account/autocomplete/website";
//    }   
// localhost+"/b2pm/contact/autocomplete/fname?q1="+companyName+"&q2"+companyWebsite+"&q3"+firstName+"&tenantId="+tenantId,{credentials:'include'}
       useEffect(() => {
        
        axios.get('./firstname.json')
        .then((response) => {
          try {
          // optionsFullSuggestions=response.data;
          setOptions(response.email)
          } catch (e) {
            console.log(e)
          }
          
        }).catch((error) => {console.log(error)})
      },[]);




    var onSuggestionSelected=async(selectedValue)=> {
        if (!selectedValue) {
            console.log("initial Load set full values", optionsFullSuggestions)
            setOptions(optionsFullSuggestions)
          return;
        }
        console.log("onChange",selectedValue);
        // onChange({ ...data, [column.field]: value.companyName })
        try {
        let response = await axios.get(localhost+ "/b2pm/account/id?accountid=" + selectedValue.accountId+"&tenantId="+tenantId,{withCredentials:true});
        if (response.status === 200 && response.data.msg ==="SUCCESS") {
          console.log("convert response", getAllRowsDatawithId(response.data, data.id))
          let dataCopy  = getAllRowsDatawithId(response.data, data.id)
          
          
          onChange({ ...data, ...dataCopy})
          // props.value=selectedValue.companyName;
        }
      } catch (e){
        toast.error(<Alert severity="error">Error Occured in autocompletion operation</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true,
        });
        console.log(e);
      }
      }
  
  
    var onInputChangeAutoComplete=async(e)=> {
      let valueInput = e.target.value;
      data.accountId="";
      data.contactId="";
      
      onChange({ ...data, [column.field]: valueInput })
      if (!valueInput || !valueInput.trim() || valueInput.trim()==="*"){
        console.log("input change is empty/whitespace")
        valueInput="*"
      }

      console.log("inputChange",valueInput);
      console.log("for the if condition", (!(valueInput.includes("*") || valueInput.includes("?") || valueInput.length > 2)));
      
//       if (!(valueInput.includes("*") || valueInput.includes("?") || valueInput.length > 2 )) {
//         setOptions([]);
//         console.log("options within if", options);
        
//         return;
//       }
      try{
      const inpValue = valueInput.trim().toLowerCase();
        let response = await axios.get(localhost+"/b2pm/contact/autocomplete/fname?q="+inpValue+"&tenantId="+tenantId,{withCredentials:true});
        let dataJson = response.data;
        setOptions(dataJson);
        
        
      } catch(e) {
        console.log("TypeAheadService error",e)
      }
      return;
    }

    return (
        <Autocomplete
        inputValue={value}
        autoComplete  
        autoHighlight
        onChange={(event, newValue) => {
          onSuggestionSelected(newValue);
          
        }}
        filterOptions={x => x}
        options={NameData.firstname}
        getOptionLabel={(option) => (option.email)}
        style={{ width: "98%", margin: "2", marginBottom:"1%" }}
        renderInput={(params) => (
          <TextField {...params} autoComplete="off" variant="standard" onChange={onInputChangeAutoComplete}/>
        )}
        
      /> 
    
      )
     
    
}


export default FieldMatchingService;

// import { TextField } from '@material-ui/core';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import React from 'react';
// import {myConstClass} from "./constants.js";
// import {useAuthState} from "@bamboobox/b2logincheck";
// import axios from 'axios';
// import { toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css'; 
// import {getAllRowsData, emptyRow, getAllRowsDatawithId, saveAccountfromRow, saveFundingfromRow, saveCRMfromRow} from './functionRepositoryDataSaveGet';
// import Button from '@material-ui/core/Button';
// import InputAdornment from '@material-ui/core/InputAdornment';
// // import Slide from '@material-ui/core/Slide';


// const localhost = myConstClass.localhost; 

// toast.configure({
//     autoClose:4000,
//      draggable: true,
//      hideProgressBar: true,
//      position: toast.POSITION.BOTTOM_LEFT,
     
       
//   });


// function FieldMatchingService(props){
//     const [open, setOpen] = React.useState(false);
//     const [getData, setData] = React.useState("");
//     // const [options, setOptions] = useState([]);
//     const userData = useAuthState();
//     let tenantId = userData.user.tenantId;
//     let userId = userData.user.userUuid;
//     let value=props.value
//     let onChange=props.onChanged
//     let data=props.data
//     let column=props.column;
//     let validator = props.validator;
//     let message = props.message;
//     let placeholder=props.placeholder;
//     let adornment=props.adornment;
//     let fieldType = props.fieldType;
//     //  let optionsFullSuggestions=[]

//     // const Transition = React.forwardRef(function Transition(props, ref) {
//     //   return <Slide direction="down" ref={ref} {...props} />;
//     // });

//     var onFocusLost=async(event)=> {

//         let inpValue= event.target.value;
//         if (!inpValue || !inpValue.trim()) {
            
//           return;
//         }
//         if (validator){
//             if(!validator(inpValue)) {
//                 toast.error(column.label+message, {
          
//                     closeOnClick: true,
            
//                     autoClose: true,
//                     closeButton: true,
//                     newestOnTop: true
//                   });
//                   return;
//             }
//         }
//         console.log("onFocusLost",inpValue);

//         try {
        
//         let response = await axios.get(localhost+"/b2pm/get/contact?tenantid="+tenantId+"&userid="+userId+"&keyword="+inpValue+"&columnToMatch="+fieldType,{withCredentials:true});
//         if (response.status === 200 && response.data.msg ==="SUCCESS" && response.data.rowSelected && response.data.rowSelected.length > 0 && 
//         response.data.rowSelected[0].account && response.data.rowSelected[0].account.accountId) {
//           console.log("convert response", getAllRowsDatawithId(response.data.rowSelected[0], data.id))
//           let dataCopy  = getAllRowsDatawithId(response.data.rowSelected[0], data.id)
          
//           setData(dataCopy);
//           setOpen(true);
          
//         }
//       } catch (e){
//         toast.error("Error Occured in Field Match operation", {
          
//           closeOnClick: true,
  
//           autoClose: true,
//           closeButton: true,
//           newestOnTop: true
//         });
//         console.log(e);
//       }
//       }

//       const handleChange = (event) => {
//         let newValue = event.target.value;
//         data.accountId="";
//         data.contactId="";
//         console.log(newValue);
//         onChange({ ...data, [column.field]: newValue });

//       };

//       const handlePopulate = (event) => {
//         // let newValue = event.target.value
        
//         onChange({ ...data, ...getData})
//         setOpen(false);
//       };

//       const handleClose = () => {
//         // setOpen(false);
//       };

//       const handleRemovePopulation = () => {
//         data=emptyRow(data.id)
//         onChange({ ...data})
//         setOpen(false);
//       };

//     return (
//         <div>
//         <Dialog
//         open={open}
//         // TransitionComponent={Transition}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{"Found this Record"}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             We found this matching {fieldType.toLowerCase()} in our database, Do you want to populate?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//         <Button onClick={handlePopulate} color="primary" variant="contained" autoFocus>
//             Yes
//           </Button>
//           <Button onClick={handleRemovePopulation} color="primary" variant="outlined" >
//             No
//           </Button>
          
//         </DialogActions>
//       </Dialog>
    
//         <TextField id="standard-basic" value={value} onBlur={onFocusLost} autoComplete="off" onChange={handleChange} placeholder={placeholder} InputProps={{
//             startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
//           }}/>
        
//         </div>
//       )
// }

// export default FieldMatchingService;