    // window._env_.API_DOMAIN;

// export const localhost = "http://localhost:8080";


// const API_BASE_URL = "http://b2prospectapi.bamboobox.in" ;
//  export const AUTH_URL  ="http://loginapi.bamboobox.in/authorize/cookie";

//  export const LOGIN_URL = "http://login.bamboobox.in";
//  const TENANT_CONFIG_API="http://b2tenantconfigapi.bamboobox.in/tenant/field/mapper/get/";

const myConstClass = {
    // localhost: "http://localhost:8080",
    //  localhost:      "http://b2prospectapi.bamboobox.in",  
    //  AUTH_URL: "http://loginapi.bamboobox.in/authorize/cookie",
    //  LOGIN_URL: "http://login.bamboobox.in",
     
    localhost:  window._env_.API_DOMAIN,
    AUTH_URL:window._env_.AUTH_URL,
    LOGIN_URL: window._env_.LOGIN_URL,
     metadataApi: window._env_.API_METADATAURL,
}


export const AUTH_URL  =window._env_.AUTH_URL;
 const API_BASE_URL = window._env_.API_DOMAIN;
export const LOGIN_URL = window._env_.LOGIN_URL;
const TENANT_CONFIG_API=window._env_.TENANT_CONFIG_API;




const EDIT_SVG = <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5V0.5C18.6274 0.5 24 5.87258 24 12.5V12.5C24 19.1274 18.6274 24.5 12 24.5V24.5C5.37258 24.5 0 19.1274 0 12.5V12.5Z" fill="#37CAD3"/>
<path d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5V0.5C18.6274 0.5 24 5.87258 24 12.5V12.5C24 19.1274 18.6274 24.5 12 24.5V24.5C5.37258 24.5 0 19.1274 0 12.5V12.5Z" fill="white" fill-opacity="0.9"/>
<path d="M18.419 8.79018L17.3494 9.85976L15.1402 7.6506L16.2098 6.58102C16.3179 6.47299 16.4912 6.47299 16.5992 6.58102L18.419 8.40077C18.527 8.5088 18.527 8.68215 18.419 8.79018ZM7.70915 19.5H5.5V17.2908L13.601 9.18982L15.8102 11.399L7.70915 19.5Z" stroke="#37CAD3"/>
</svg>

const CANCEL_SVG = <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5V0.5C18.6274 0.5 24 5.87258 24 12.5V12.5C24 19.1274 18.6274 24.5 12 24.5V24.5C5.37258 24.5 0 19.1274 0 12.5V12.5Z" fill="#FEECEB"/>
<path d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z" fill="#F7685B"/>
</svg>

const SAVE_SVG = <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5Z" fill="#EDF7ED"/>
<path d="M8.99991 16.6698L4.82991 12.4998L3.40991 13.9098L8.99991 19.4998L20.9999 7.49984L19.5899 6.08984L8.99991 16.6698Z" fill="#7BC67E"/>
</svg>

const TRASH_ICON = <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 12.5C0 5.87258 5.37258 0.5 12 0.5V0.5C18.6274 0.5 24 5.87258 24 12.5V12.5C24 19.1274 18.6274 24.5 12 24.5V24.5C5.37258 24.5 0 19.1274 0 12.5V12.5Z" fill="#FEECEB"/>
<path d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z" fill="#F7685B"/>
</svg>
const styles = {
 select: { marginBottom: '20px', marginTop: '20px', width: '95%', height: '50%', outline:'none',display: 'flex', justifyContent: 'flex-center', alignItems: 'center', borderColor: '#0000', borderBottom: '1px solid #eee', borderRadius: '0%', background: '#fefefe',padding: '0%', },
 editButton: {
    background: "#f3f3f3",
    outline: "none",
    cursor: "pointer",
    padding: 0,
    display: "inline-flex",
    border: "none",
    borderRadius: "50%",
    boxShadow: "1px 1px 2px 0px rgb(0 0 0 / .3)",
  },
  buttonsCellEditorContainer: {
    height: "100%",
    width: "100%",
    display: "inline-flex",
    padding: "0 5px",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  cancelButton: {
    background: "#f3f3f3",
    outline: "none",
    cursor: "pointer",
    marginRight: 10,
    padding: 0,
    display: "inline-flex",
    border: "none",
    borderRadius: "50%",
    boxShadow: "1px 1px 2px 0px rgb(0 0 0 / .3)",
  },
  saveButton: {
    background: "#f3f3f3",
    outline: "none",
    cursor: "pointer",
    padding: 0,
    display: "inline-flex",
    border: "none",
    borderRadius: "50%",
    boxShadow: "1px 1px 2px 0px rgb(0 0 0 / .3)",
  },
  deleteButton: {
    background: "#f3f3f3",
    outline: "none",
    cursor: "pointer",
    marginLeft: 9,
    padding: 0,
    display: "inline-flex",
    border: "none",
    borderRadius: "50%",
    boxShadow: "1px 1px 2px 0px rgb(0 0 0 / .3)",
  },
}
  

export {API_BASE_URL, myConstClass, EDIT_SVG, CANCEL_SVG, SAVE_SVG, TRASH_ICON, styles, TENANT_CONFIG_API};
