import React, { useEffect, useState } from "react";
import {TextField, Input} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {myConstClass} from "./constants.js";
import {useAuthState} from "@bamboobox/b2logincheck";
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FieldMatchingService from './FieldMatchingComponent';
import {getAllRowsData, getAllRowsDatawithId, emptyRow, emptyField, saveAccountfromRow, saveFundingfromRow, saveCRMfromRow} from './functionRepositoryDataSaveGet';
import './toast.css';
import Alert from '@mui/material/Alert';

const localhost = myConstClass.localhost; 
let emailFlag = false;
let secondEmailFlag = false;
let onFocusEntered = false;	

toast.configure({
    autoClose:6000,
     draggable: true,
     hideProgressBar: true,
     position: toast.POSITION.BOTTOM_LEFT,
     
       
  });

function TypeAheadServiceContact(props){
    console.log("Type Ahead Service");
    const userData = useAuthState();
    const [options, setOptions] = useState([]);
    const [secondaryEmailOptions, setSecondaryEmailOptions] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [getData, setData] = React.useState("");
    const [inputValue, setInputValue] = useState("");
    
    let tenantId = userData.user.tenantId;
    let userId = userData.user.userUuid;
    let validator = props.validator;
    let value=props.value
    let onChange=props.onChanged
    let data=props.data
    let column=props.column;
    let message=props.message;
    let optionsFullSuggestions=[];
    let disabled = props.disabled

    console.log("Props for this service is ",props);
    console.log("Props Disabled: "+props.disabled);

    let baseUrl = "/b2pm/contact/autocomplete/";
    let url = "";
    let param1 = '*';
    let param2 = '*';
    let exactMatch = false;
    
    url = formuLateCompleteURL(baseUrl,column, param1,param2,exactMatch);
    url = formuLateCompleteURL(baseUrl,column, param1,param2,exactMatch);
    url = formuLateCompleteURL(baseUrl,column, param1,param2,exactMatch);
    url = formuLateCompleteURL(baseUrl,column, param1,param2,exactMatch);
    
    function formuLateCompleteURL(url, column, param1, param2, exactMatch)
    {
      let compUrl= url;
      if(column.field == "contactFirstName")
      {
        compUrl += `fname?q1=${param1}&q2=${param2}`;
      }
      else if(column.field == "contactLastName")
      {
        compUrl += `lname?q1=${param1}&q2=${param2}`;

      } 
      else if(column.field =="contactEmail")
      {
        compUrl += `email?q1=${param1}&q2=${param2}&exactMatch=`+exactMatch;
        emailFlag = true;
        secondEmailFlag = false;
      } 
      else if (column.field =="contactSecondaryEmail")
      {
        compUrl += `secondaryEmail?q1=${param1}&q2=${param2}&exactMatch=`+exactMatch;
        emailFlag = false;
        secondEmailFlag = true;
      }
        return compUrl;
    }

    useEffect(() => {
      
      console.log("In useEffect....")
      
      axios.get(localhost+url+"&tenantId="+tenantId,{withCredentials:true})
      
      .then((response) => {
        try {
          optionsFullSuggestions=response.data.data;
          //Check for response.data.found here...
          let contactSecondaryEmailData = [];
          response.data.data.forEach(
            (element) => {
              if(element.contactSecondaryEmail != null){
                console.log(element);
                contactSecondaryEmailData.push(element);
              }
            }
          );
          console.log("Contact Secondary email data");
          console.log(contactSecondaryEmailData);
          setOptions(response.data.data);
          setSecondaryEmailOptions(contactSecondaryEmailData);
          console.log("website options",options);
          console.log("options",options);
        
        } catch (e) {
            console.log(e);
          }
        
      }).catch((error) => {console.log(error)})
    },[]);


    useEffect(() => {
      
      console.log("In website useEffect....");
      //console.log("data.props.website", data.props.companyWebsite);
      let website = data.companyWebsite.trim().toLowerCase();
        if (!website || !website.trim() || website.trim()==="*"){
          console.log("website is empty/whitespace");
          website="*"
      }
      url = formuLateCompleteURL(baseUrl,column, param1,website, exactMatch);
      url = formuLateCompleteURL(baseUrl,column, param1,website, exactMatch);
      url = formuLateCompleteURL(baseUrl,column, param1,website, exactMatch);
      url = formuLateCompleteURL(baseUrl,column, param1,website, exactMatch);
    
      axios.get(localhost+url+"&tenantId="+tenantId,{withCredentials:true})
      
      .then((response) => {
        try {
          console.log("In website useEffect, Response Received is ", JSON.stringify(response));
          console.log("Email response is ",response.data.data);
          optionsFullSuggestions=response.data.data;
          let contactSecondaryEmailData = [];
          response.data.data.forEach(
            (element) => {
              if(element.contactSecondaryEmail != null){
                contactSecondaryEmailData.push(element);
              }
            }
          );
          console.log("Contact Secondary email data");
          console.log(contactSecondaryEmailData);
          setOptions(response.data.data);
          setSecondaryEmailOptions(contactSecondaryEmailData);
          console.log("website options",options);
        
        } catch (e) {
            console.log(e);
          }
        
      }).catch((error) => {console.log(error)})
    },[props.data.companyWebsite]);

    var onSuggestionSelected=async(selectedValue)=> {
        if (!selectedValue) {
          console.log("initial Load set full values", optionsFullSuggestions)
          setOptions(optionsFullSuggestions)
          return;
        }
        console.log("onChange",selectedValue);
        // onChange({ ...data, [column.field]: value.companyName })
        try {
          let response = await axios.get(localhost+ "/b2pm/contact/id?contactid=" + selectedValue.contactId+"&tenantId="+ tenantId, {withCredentials:true});
          if (response.status === 200 && response.data.msg ==="SUCCESS") {
            console.log("convert response", getAllRowsDatawithId(response.data, data.id))
            let dataCopy  = getAllRowsDatawithId(response.data, data.id)
            const newData = { ...data, ...dataCopy};
            newData.crmContactGeography = newData.crmAccountGeography;
            newData.contactRegion = newData.companyAccountRegion;
            let contactSecondaryEmailData = [];
            if(newData.contactSecondaryEmail != null){
              contactSecondaryEmailData.push(newData);
            }
            console.log("Contact Secondary email data");
            console.log(contactSecondaryEmailData);
            onChange({ ...newData })
            setSecondaryEmailOptions(contactSecondaryEmailData);
        }
      } catch (e){
        
        toast.error(<Alert severity="error">Error Occured in autocompletion operation</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true,
        });
        console.log(e);
      }
    }

  const handleClose = () => {
    setOpen(false);
  };
  var onFocusLost=async(e)=> {
    console.log("Focus Lost");
      
        console.log("event",e);
        let valueInput = e.target.value;
        //data.accountId="";
        //data.contactId="";

        console.log("In onFocusLost, data is ", data, "valueInput is", valueInput);
        if(valueInput.trim() === ""){
          return;
        }
        onChange({ ...data, [column.field]: valueInput })
        if (!valueInput || !valueInput.trim() || valueInput.trim()==="*"){
          console.log("input change is empty/whitespace")
          valueInput="*"
        }
        if (validator){
          if(!validator(valueInput.trim())) {
                toast.error(<Alert severity="error">{column.label+message}</Alert>,{
                  closeOnClick: true,
                  autoClose: true,
                  closeButton: true,
                  newestOnTop: true,
                });
                
                return;
          }
        }
        console.log("inputChange",valueInput, "column field ", column.field);
        console.log("for the if condition", (!(valueInput.includes("*") || valueInput.includes("?") || valueInput.length > 2)));
        
        try{
            const inpValue = valueInput.trim().toLowerCase();
            let website = data.companyWebsite.trim().toLowerCase();
            if (!website || !website.trim() || website.trim()==="*"){
              console.log("website is empty/whitespace");
              website="*"
            }

            console.log("inpValue",inpValue, "website",website);

            //API ... If user tabs out when he is searching for primaryEmail, hit the secondaryEmailAPI
            let response;
            if(column.field === "contactEmail"){
              let newCol = {};
              newCol.field = "contactSecondaryEmail";
              url = formuLateCompleteURL(baseUrl, newCol, inpValue,website, true);
              console.log("in onFocusLost, url is ", url);
              response = await axios.get(localhost+url+"&tenantId="+tenantId,{withCredentials:true})
            }
            else if(column.field === "contactSecondaryEmail"){
              let newCol = {};
              newCol.field = "contactEmail";
              url = formuLateCompleteURL(baseUrl, newCol, inpValue,website, true);
              console.log("in onFocusLost, url is ", url);
              response = await axios.get(localhost+url+"&tenantId="+tenantId,{withCredentials:true})
            }
            
            let dataJson = response.data.data;
            let found = response.data.found;
            if(found === "contactSecondaryEmail" && valueInput !== "*" && column.field === "contactEmail"){
              //Email was found in secondary email, show a popup here.
              emailFlag = true;
              secondEmailFlag = false;
              try{
                setOpen(true);
                setData(dataJson[0]);
                console.log("open",open);
              }
              catch(e){
                console.log("error",e)
              }
            }
            else if(found === "contactEmail" && valueInput !== "*" && column.field === "contactSecondaryEmail"){
              emailFlag = false;
              secondEmailFlag = true;
              try{
                setOpen(true);
                setData(dataJson[0]);
                if(dataJson[0].contactSecondaryEmail == null){
                  console.log("Secondary Email is null");
                  emptyField(dataJson[0], "contactSecondaryEmail");
                  setInputValue(valueInput);
                }
                console.log("open",open);
              }
              catch(e){
                console.log("error",e)
              }
            }
            else{
              //Do nothing0
              console.log("Do nothing");
            }
              
          } catch(e) {
            console.log("TypeAheadService error",e)
        }
        return;
    }
  
    var onInputChangeAutoComplete=async(e)=> {
      console.log("event",e);
      let valueInput = e.target.value;
      //data.accountId="";
      //data.contactId="";
      let exactMatch = false;
      console.log("In onInputChangeAutoComplete, data is ", data, "valueInput is", valueInput);

      onChange({ ...data, [column.field]: valueInput })

      if (!valueInput || !valueInput.trim() || valueInput.trim()==="*"){
        console.log("input change is empty/whitespace")
        valueInput="*"
      }

      console.log("inputChange",valueInput, "column field ", column.field);
      console.log("for the if condition", (!(valueInput.includes("*") || valueInput.includes("?") || valueInput.length > 2)));
      
      try{
        const inpValue = valueInput.trim().toLowerCase();
        
        let website = data.companyWebsite.trim().toLowerCase();
        if (!website || !website.trim() || website.trim()==="*"){
          console.log("website is empty/whitespace");
          website="*"
        }

        console.log("inpValue",inpValue, "website",website);
        url = formuLateCompleteURL(baseUrl,column, inpValue,website,exactMatch);
        console.log("in onInputChangeAutoComplete, url is ", url);

        let response = await axios.get(localhost+url+"&tenantId="+tenantId,{withCredentials:true})
        let dataJson = response.data.data;
        let contactSecondaryEmailData = [];
        dataJson.forEach(
          (element) => {
            if(element.contactSecondaryEmail != null){
              contactSecondaryEmailData.push(element);
            }
          }
        );
        console.log("Contact Secondary email data");
        console.log(contactSecondaryEmailData);
        setOptions(dataJson);
        setSecondaryEmailOptions(contactSecondaryEmailData);
        console.log("Secondary Email Options");
        console.log(setSecondaryEmailOptions);
      } catch(e) {
        console.log("TypeAheadService error",e)
      }
      return;
    }

    const handlePopulate = (event) => {
      // let newValue = event.target.value
      
      onChange({ ...data, ...getData})
      setOpen(false);
    };

    const handleRemovePopulation = () => {
      data=emptyRow(data.id)
      onChange({ ...data})
      setOpen(false);
    };

    var onFocusEntered=async(e)=> {
      console.log("Focused............ ",e);
      onFocusEntered = true;
    }

    return (
      <>
        <Autocomplete
        inputValue={value}
        autoComplete  
        autoHighlight
        onChange={(event, newValue) => {
          onSuggestionSelected(newValue);
        }}
        filterOptions={x => x}
        //disabled = {((column.field === "companyName" || column.field === "companyWebsite") && props.data.accountId !=="" && props.tab === "contact" ? true : false)}
        options= {(column.field !== "contactSecondaryEmail") ? options : secondaryEmailOptions}
        //options = {options}
        getOptionLabel={option => option[column.field]}
        style={{ width: "98%", margin: "2", marginBottom:"1%" }}
        renderInput={(params) => (
          <TextField {...params} autoComplete="off" variant="standard" onChange={onInputChangeAutoComplete} onBlur={onFocusLost}/>
        )}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Found this Record"}</DialogTitle>
        <DialogContent>
          {emailFlag ? <DialogContentText id="alert-dialog-description">
            We found this matching other email in our database, Do you want to populate?
          </DialogContentText> : null}
          {secondEmailFlag ? <DialogContentText id="alert-dialog-description">
            We found this matching official email in our database, Do you want to populate?
          </DialogContentText> : null}
        </DialogContent>
        <DialogActions>
        <Button onClick={handlePopulate} color="primary" variant="contained" autoFocus>
            Yes
          </Button>
          <Button onClick={handleRemovePopulation} color="primary" variant="outlined" >
            No
          </Button>
        </DialogActions>
      </Dialog>
      </>
    )
}


export default TypeAheadServiceContact;
