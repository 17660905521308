import React, {  useState, useEffect } from "react";
import axios from 'axios';
import { css } from "glamor";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import "./gridindex.css";
import "./getAccountColumn.css";
import DropdownWithMeta from "./DropdownWithMeta"
//import {localhost_base_url} from "./constants.js";
import {API_BASE_URL, myConstClass, EDIT_SVG, CANCEL_SVG, SAVE_SVG, TRASH_ICON, styles} from "./constants.js";
import {useAuthState, AuthProvider} from "@bamboobox/b2logincheck";
import uuid from 'uuid-random';
import {getAllRowsData, trimOrSendEmpty, check_mandatory_validate_accountFields, getAllRowsDatawithId, saveAccountfromRow, saveFundingfromRow, saveCRMfromRow} from './functionRepositoryDataSaveGet';
import {validateUrl, validateStringRegex, validateEmail, validateFloat, validateInteger} from './validateInput';
import TypeAheadService from './TypeAheadService'
import FieldMatchingService from './FieldMatchingService';
import ValidationUIComponent from './ValidationUIComponent';
import './toast.css';
import Alert from '@mui/material/Alert';

// , EDIT_SVG, CANCEL_SVG
//const localhost = window._env_.API_DOMAIN;

var accountClassification = [];
var industriesList = [];
var subindustriesList = [];
var countriesList = [];
var crmAccountRegionsList = [];
var statesListByCountry = [];
var companyType = [];
var companyHierarchy = [];
var capillaryGeography = [];
var crmBusinessFormatList = [];
var isExecList = [];
var fsExecList = [];
let allMetaDataList = []

//Snackbar
toast.configure({
  autoClose:6000,
   draggable: true,
   hideProgressBar: true,
   position: toast.POSITION.BOTTOM_LEFT,
   
     
});

let optionsForTypeAhead = []

const localhost = myConstClass.localhost;  
// let tempBugFix = 0;
const GetColumns = ({ setRowsData, metadata, stateMetaData, fsExecdata, isExecMetaData, allMetadata }) => {
  const userData = useAuthState();
  // const [error, setError] = useState(false);
  let tenantId = userData.user.tenantId;
  let userId = userData.user.userUuid;
  toast.configure({
    autoClose:6000,
     draggable: true,
     hideProgressBar: true,
     position: toast.POSITION.BOTTOM_LEFT,
     
       
  });
  allMetaDataList = [...allMetadata]
  console.log("above metadata", metadata, allMetaDataList);
  if (metadata != undefined && metadata.length > 0 )
 {
   console.log("within metadata");
   accountClassification = metadata.find(obj => obj.name == "accountClassification");
   if (accountClassification){
    accountClassification=accountClassification.values;
   }else {
    accountClassification = []
   }
   industriesList = metadata.find(obj => obj.name == "industry");
   if(industriesList){
     industriesList=industriesList.values;
   }else {
    industriesList = []
   }

   crmBusinessFormatList = metadata.find(obj => obj.name == "businessFormat");
   if(crmBusinessFormatList){
     crmBusinessFormatList=crmBusinessFormatList.values;
   }else {
    crmBusinessFormatList = []
   }

   countriesList = metadata.find(obj => obj.name == "country")
   if(countriesList){
     countriesList=countriesList.values;
   }else {
    countriesList = []
   }
   // statesListByCountry = stateMetaData;
   subindustriesList = metadata.filter(obj => obj.name == "subIndustry")
   if (subindustriesList){
     if (subindustriesList.length === 1){
       subindustriesList=subindustriesList[0].values;
     } else if (subindustriesList.length === 2){
       subindustriesList=subindustriesList[1].values;
     } else {
       subindustriesList = [];
     }
   }
   crmAccountRegionsList = metadata.filter(obj => obj.name == "accountRegion");
   if (crmAccountRegionsList){
     if (crmAccountRegionsList.length === 1){
       crmAccountRegionsList=crmAccountRegionsList[0].values;
     } else if (crmAccountRegionsList.length === 2){
       crmAccountRegionsList=crmAccountRegionsList[1].values;
     } else {
       crmAccountRegionsList = [];
     }
   }
   companyType = metadata.find(obj => obj.name == "companyType")
   if (companyType){
     companyType = companyType.values;
   } else {
    companyType = []
   }
   companyHierarchy = metadata.find(obj => obj.name == "companyHierarchy")
   if (companyHierarchy){
     companyHierarchy = companyHierarchy.values;
   }else {
    companyHierarchy = []
   }
   capillaryGeography = metadata.find(obj => obj.name == "geography")
   if (capillaryGeography){
     capillaryGeography = capillaryGeography.values;
   }
   else {
    capillaryGeography = []
   }
   if (fsExecdata){
     fsExecList=[]
     console.log("fsExecdata",fsExecdata);
     for (let i=0; i < fsExecdata.length; i++){
       fsExecList.push(fsExecdata[i])
       fsExecList[i].value = fsExecdata[i].name;
     }
   } else {
     fsExecList = [];
   }

   if (isExecMetaData){
     isExecList=[]
     console.log("isExecMetaData",isExecMetaData);
     for (let i=0; i < isExecMetaData.length; i++){
       isExecList.push(isExecMetaData[i])
       isExecList[i].value = isExecMetaData[i].name;
     }
   } else {
     isExecList = [];
   }
 }


  function addNewRow(rowsClone,z){   

   
    var obj = {
      "id": uuid(), 
      "row_id": z,
      "msg": "",
      "accountSource": "Manual",
      "accountId": "",
      "companyName": "",
      "companyGroupName": "",
      "companyWebsite": "",
      "companyParentWebsite": "",
      "companyGroupWebsite": "",
      "companyPrimaryIndustryCategory": "",
      "companySubIndustryCategory": "",
      "companyBrandRevenue": "",
      "companyEmployeeCount": "",
      "companyFax": "",
      "companyPhone": "",
      "companyStreet": "",
      "companyCity": "",
      "companyState": "",
      "companyCountry": "",
      "companyContinent": "",
      "companyZipCode": "",
      "companyDescription": "",
      "companyLinkedin": "",
      "companyFacebook": "",
      "companyTwitter": "",
      "companyParent": "",
      "companyHierarchy": "",
      "companyLocationCount": "",
      "companyProducts": "",
      "companyType": "",
      "companyClassification": "",
      "companyTechAttributes": "",
      "companyHashTag": "",
      "companyRecentFundingAmount": "",
      "companyRecentFundingDate": "",
      "companyTotalFundingAmount": "",
      "companyFundingList": "",
      "companyAccountRegion": "",
      "crmFmExecutive": "",
      "crmNumberofStores": "",
      "crmBusinessFormat": "",
      "crmISExecutive": "",
      "crmFSExecutive": ""
    };

    var data = rowsClone;
    data.unshift(obj);

    setRowsData([...data]);

    console.log(rowsClone);

  //  console.log(rowsClone[0].id);
  //  console.log(rowsClone[1].id);
  //  console.log(rowsClone[0].id === rowsClone[1].id);
  if(rowsClone.length>1) { 
  if(rowsClone[0].id === rowsClone[1].id )
    {

      rowsClone.splice(1,1);
      setRowsData(rowsClone)
    }
  }
  }
    // metadata = undefined;
    
    var saveadapt = {
       
        "tenantId":tenantId ,// "TTT-ID-00000",
        "userId":userId ,// "USR-ID-0000",
        "msg" :"",
        "rowSelected": []
    }


    const deleteSingleRow = (e,rowIndex,rowsClone)=>{
   
    
         if(rowsClone[rowIndex-1]["accountId"] === "")
         {
          
            toast.error(<Alert severity="error">First Empty row cannot be deleted</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true,
          });
         }
      //   else if(rowsClone[rowIndex-1]["accountId"] === "" && rowsClone[rowIndex-1]["companyName"])
         else{
      console.log(rowIndex);
      var cId = rowsClone[rowIndex-1]["accountId"];
      console.log(rowsClone[rowIndex-1]["accountId"]);
      axios.delete(localhost+"/b2pm/deletedata/"+tenantId+"/"+userId+"/account/"+cId,{withCredentials:true})
      rowsClone.splice(rowIndex-1,1);  
      var data = rowsClone;
      console.log(rowsClone.length)
      setRowsData([...data]);
    }
      
  }
  







     

function checkWebsite(rowsClone, updatedRowIndex)
{
     if(rowsClone.length > 1){

      console.log(updatedRowIndex);
      console.log(rowsClone[updatedRowIndex]);
        console.log(rowsClone);


     for(let i = 1; i< rowsClone.length;i++)
      
       {
        console.log(rowsClone[i] );
          console.log(rowsClone[i].companyWebsite );
        
       
        if(rowsClone[updatedRowIndex].companyWebsite.trim().toLowerCase() === rowsClone[i].companyWebsite.trim().toLowerCase() )
      {       
        if(updatedRowIndex !== i)
        {
          return true;
        }
       
              // alert("Entered Company Website " + rowsClone[updatedRowIndex].companyWebsite.trim().toLowerCase() + " is already present in the grid.")
              // rowsClone.splice(1,1);
              // setRowsData(rowsClone);
              

      }
    }
       return false;

    }
    
  
}





    const save_data  = async(rowsClone, updatedRowIndex) => {
 
    // console.log("SAVEDATA");
    console.log(rowsClone);
     console.log(rowsClone[updatedRowIndex]);
   
   
     console.log(updatedRowIndex);
    // console.log("-------------")
    //console.log("Before "+rowsClone.length);

    var json = {"row_id" : updatedRowIndex+1 , "accountSource" : rowsClone[updatedRowIndex].accountSource,"msg" : rowsClone[updatedRowIndex].msg };
 
    
    var account = saveAccountfromRow(rowsClone[updatedRowIndex])
    json["account"] = account;
    
    var funding = saveFundingfromRow(rowsClone[updatedRowIndex])
    json["funding"] = funding;
    var crm= saveCRMfromRow(rowsClone[updatedRowIndex])
    json["crm"] = crm;
    saveadapt.rowSelected=[];
    saveadapt.rowSelected.push(json);
   // console.log("Push SAVEDATA");
    console.log(saveadapt);
    console.log(rowsClone);
    console.log(rowsClone.length);
     console.log(rowsClone[updatedRowIndex]);

          var resp = "";  
      resp = await axios.post(localhost+"/b2pm/savedata/account", saveadapt,{withCredentials:true});
      
    
      // let length = tabledata.data.rowSelected.length-1;
      // let currentrow = tabledata.data.rowSelected[length];
      //    // console.log(currentrow);
      // let row =  {id: rowsClone[updatedRowIndex].id,
      //             row_id: rowsClone[updatedRowIndex].id,
      //             msg:currentrow.msg,
      //             accountSource: currentrow.accountSource,
      //             ...currentrow.account,...currentrow.crm,...currentrow.funding};
        
      // (function removeNull(o) {
      //     for(var key in o) {
      //         if( null === o[key] ) o[key] = '';
      //         if ( typeof o[key] === 'object' ) removeNull(o[key]);
      //     }
      //  })(row);
      
      // rowsClone[updatedRowIndex] = row;

  
   
     // setRowsData(rowsClone);
     
     
          //  SNACKBAR TOAST
               
            // if(rowsClone[1].accountId == "")
            //        {  
                      
            //            rowsClone.splice(0,1);
            //             //console.log(rowsClone)
            //             setRowsData(rowsClone);
                       
            //          }
            //console.log("After" +rowsClone.length);
           //if(rowsClone.length === )
           console.log(resp)
      if (resp.status == 200) {
           if((resp.data.rowSelected[0].msg === "SUCCESS") &&
           ( resp.data.rowSelected[0].account.accountId !=="") &&
           ( resp.data.rowSelected[0].account.accountId !==null))
           {
            
          toast.success(<Alert severity="success">Account has been added/edited successfully!</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true,
          });

         // rowsClone[updatedRowIndex] = resp.data.rowSelected[0]
         console.log("BEFORE");
          console.log(rowsClone);
          console.log(updatedRowIndex)
            rowsClone[updatedRowIndex] = getAllRowsData(resp.data.rowSelected[0],updatedRowIndex)
            console.log( getAllRowsData(resp.data.rowSelected[0],updatedRowIndex+1))
            setRowsData(rowsClone);
            console.log("AFTER");
            console.log(rowsClone);
            
        }
          else{
              
              toast.error(<Alert severity="error">{resp.data.rowSelected[0].msg} Data saving unsuccessful.</Alert>,{
                closeOnClick: true,
                autoClose: true,
                closeButton: true,
                newestOnTop: true,
              });
    
          //   let newObj = addNewOnDelete(rowsClone, )
          //   console.log(rowsClone)
          //  // console.log(rowsClone[updatedRowIndex])
          // console.log(rowsClone)
          

          // //  setRowsData(dataRow);
          // // var dataRows = rowsClone;
          // //  console.log(dataRows)
          // //  console.log(newObj)
          //  var dataRows=rowsClone[0]
        //  setRowsData([...dataRows],rowsClone)
          // //   //addNewRow()

          }


            
         }


     
         
  }
  return [
    {
    id: "checkbox",
    visible: true,
    pinned: true,
    width: "54px",
    },
    // {
    // id: "accountId",
    // field: "accountId",
    // label: "AccountID",
    // visible:false,
    // searchable: false,
    // editable: false,
    // sortable: false,
    // resizable: false,
    // },
    {
    id: "companyName",
    field: "companyName",
    label: "Company Name" ,
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    headerCellRenderer:({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => ( <span onChange={ onChange } required>Company Name<span id="red_color">&#42;</span></span> ),
    editorCellRenderer: ({
            tableManager,
            value,
            data,
            column,
            colIndex,
            rowIndex,
            onChange
          }) => <TypeAheadService value={value} data={data} column={column} onChanged={onChange} />

    },


    {
    id: "companyWebsite",
    field: "companyWebsite",
    label: "Company Website",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    headerCellRenderer:({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => ( <span onChange={ onChange } required>Company Website<span id="red_color">&#42;</span></span> ),
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
      }) =><TypeAheadService value={value} data={data} column={column} onChanged={onChange} />
  
  
  },
    {
    id: "companyParent",
    field: "companyParent",
    label: "Parent Company",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
    
    },
    {
    id: "companyGroupName",
    field: "companyGroupName",
    label: "Group Name",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
    
    },
   
    
   
    {
    id: "companyParentWebsite",
    field: "companyParentWebsite",
    label: "Parent Website",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not a valid website" placeholder="example.com"/>
    },
    {
    id: "companyGroupWebsite",
    field: "companyGroupWebsite",
    label: "Group Website",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not a valid website" placeholder="example.com"/>
    },
    {
    id: "companyPrimaryIndustryCategory",
    field: "companyPrimaryIndustryCategory",
    label: "Industry",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={industriesList} />
    },
    {
    id: "companySubIndustryCategory",
    field: "companySubIndustryCategory",
    label: "Sub-Industry",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={subindustriesList} level="1" parent="companyPrimaryIndustryCategory"/>
    },
   
    {
    id: "companyBrandRevenueNumerical",
    field: "companyBrandRevenueNumerical",
    label: "Company Revenue",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateFloat} message=" should be a decimal value" adornment="$" placeholder="1000000.00"/>
    
    },

    
    {
    id: "companyPhone",
    field: "companyPhone",
    label: "Phone",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
    
    },
    {
      id: "companyCountry",
      field: "companyCountry",
      label: "Mailing Country",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={countriesList} />
      },
   
    {
    id: "companyState",
    field: "companyState",
    label: "Mailing State",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
    
    // editorCellRenderer: ({
    //   tableManager,
    //   value,
    //   data,
    //   column,
    //   colIndex,
    //   rowIndex,
    //   onChange
    // }) => <DropdownWithMeta propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={statesListByCountry} parent={"companyCountry"} state={true} />
    
    },
   
    {
      id: "companyCity",
      field: "companyCity",
      label: "Mailing City",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
      
      // editorCellRenderer: ({
      //   tableManager,
      //   value,
      //   data,
      //   column,
      //   colIndex,
      //   rowIndex,
      //   onChange
      // }) => <DropdownWithMeta propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={[]} parent={"companyState"} city={true} />
      }, 
   
    {
    id: "19",
    field: "companyDescription",
    label: "Company Description",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
    
    },
   
    {
    id: "20",
    field: "companyLinkedin",
    label: "Linkedin URL",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not a valid website" placeholder="example.com"/>
    
    },
   
    {
    id: "21",
    field: "companyFacebook",
    label: "FaceBook URL",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not a valid website" placeholder="example.com"/>
    
    },
   
    {
    id: "22",
    field: "companyTwitter",
    label: "Twitter URL",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateUrl} message=" is not a valid website" placeholder="example.com"/>
    
    },
    
    {
    id: "companyType",
    field: "companyType",
    label: "Company Type",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={companyType} />
    
    },
   
    {
    id: "companyHierarchy",
    field: "companyHierarchy",
    label: "Company Hierarchy",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={companyHierarchy} />
    
    },
   
    {
    id: "companyTechAttributes",
    field: "companyTechAttributes",
    label: "Tech Attributes",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateStringRegex} message=" should not have special character" />
    
    },
    {
      id: "companyClassification",
      field: "companyClassification",
      label: "Account Classification",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={accountClassification} />
      },
    
    {
    id: "crmFSExecutive",
    field: "crmFSExecutive",
    label: "Field Sales",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={fsExecList} />
    
    },
    {
    id: "crmISExecutive",
    field: "crmISExecutive",
    label: "Inside Sales",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={isExecList} />
    
    },
    // {
    // id: "crmFmExecutive",
    // field: "crmFmExecutive",
    // label: "Field Marketing",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
    {
    id: "crmBusinessFormat",
    field: "crmBusinessFormat",
    label: "Business Format",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    editorCellRenderer: ({
      tableManager,
      value,
      data,
      column,
      colIndex,
      rowIndex,
      onChange
    }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={crmBusinessFormatList} />
    
    },
    {
      id: "crmNumberOfStores",
      field: "crmNumberOfStores",
      label: "Number of Stores",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      editorCellRenderer: ({
        tableManager,
        value,
        data,
        column,
        colIndex,
        rowIndex,
        onChange
      }) => <ValidationUIComponent  value={value} data={data} column={column} onChanged={onChange} validator={validateInteger} message=" should be a number only" placeholder="100"/>
      
      },
      {
        id: "crmAccountGeography",
        field: "crmAccountGeography",
        label: "Capillary Geography",
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        editorCellRenderer: ({
          tableManager,
          value,
          data,
          column,
          colIndex,
          rowIndex,
          onChange
        }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={capillaryGeography} />
        
        },
        {
          id: "companyAccountRegion",
          field: "companyAccountRegion",
          label: "Account Region",
          visible: true,
          searchable: true,
          editable: true,
          sortable: true,
          resizable: true,
          editorCellRenderer: ({
            tableManager,
            value,
            data,
            column,
            colIndex,
            rowIndex,
            onChange
          }) => <DropdownWithMeta allMetaData={allMetaDataList} source="ACCOUNT" propstyles={styles} value={value} data={data} column={column} onChange={onChange} valueArray={crmAccountRegionsList} level="1" parent="crmAccountGeography"/>
          
          },
    {
    id: "39",
    field: "accountSource",
    label: "Account Source",
    visible: true,
    searchable: true,
    editable: false,
    sortable: true,
    resizable: true,
    
    
    },
    // {
    // id: "msg",
    // field: "msg",
    // label: "msg",
    // visible: false,
    // searchable: false,
    // editable: false,
    // sortable: false,
    // resizable: true,
    // },
    
    {
    id: "buttons",
    
    width: "max-content",
    visible: true,
    pinned: true,
    sortable: false,
    resizable: false,
    cellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex }) => (
    <div style={styles.buttonsCellContainer}>
    <button
    title="Edit"
    style={styles.editButton}
    onClick={(e) => {
    e.stopPropagation();
    tableManager.rowEditApi.setEditRowId(data.id);
    }}
    >
    {EDIT_SVG}
    </button>
    <button
    id = {rowIndex}
    title="Delete"
    style={styles.deleteButton}
    onClick={e=> deleteSingleRow(e,rowIndex,[...tableManager.rowsApi.rows])}
    >
                   
                    {TRASH_ICON}
                    </button>
    </div>
    ),
    editorCellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex, onChange, row }) => (
    <div style={styles.buttonsCellEditorContainer}>
    <button
    title="Cancel"
    style={styles.cancelButton}
    onClick={(e) => {
      e.stopPropagation();
      const newData={};
      for(const key in data) 
      { if(key!=="id") newData[key]=""}
      newData.id=data.id;
      onChange({...newData});
    }}
    >
    {CANCEL_SVG}
    </button>
    <button
    title="Save"
    style={styles.saveButton}
    onClick={(e) => {
    e.stopPropagation();
    let rowsClone = [...tableManager.rowsApi.rows];
    
    let updatedRowIndex = rowsClone.findIndex((r) => r.id === data.id);
    
    //console.log("localhost"+localhost+JSON.stringify(myConstClass));
    // console.log([...tableManager.rowsApi.rows]);
    // console.log(rowsClone[updatedRowIndex]);
    rowsClone[updatedRowIndex] = data;
    
    // if(error) {
      
    //   tableManager.rowEditApi.setEditRowId(data.id);
    //   return;
    //   }
    let [mandatoryFieldsError, validationFieldErrorList] = check_mandatory_validate_accountFields(rowsClone[updatedRowIndex])
    
    if (mandatoryFieldsError) {
      
      console.log("mandatory fields error", mandatoryFieldsError)
      toast.error(<Alert severity="error">{mandatoryFieldsError}</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true,
      });
      return;
    }
    
    if (validationFieldErrorList.length > 0){
      console.log("validationFieldErrorList size", validationFieldErrorList.length)
      for (let i=0; i < validationFieldErrorList.length; i++) {
        
        console.log("validationFieldErrorList",validationFieldErrorList[i])

        toast.error(<Alert severity="error">{validationFieldErrorList[i]}</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true,
        });
      }
      return;
    }

    

      if(checkWebsite(rowsClone, updatedRowIndex))
     
     {
      
      toast.error(<Alert severity="error">Entered record is already present in the grid</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true,
      });
         
     }  

     else
      {
        console.log("rowinx" + updatedRowIndex);
     // tableManager.rowEditApi.setEditRowId(data.id+1);
      console.log(rowsClone);
       save_data(rowsClone, updatedRowIndex)
       .then((r) => {
      console.log(rowsClone[0])
        if((rowsClone[0].companyName!== "") && (rowsClone[0].companyWebsite !== "") && (rowsClone[0].accountId !== "") )
        {
          // setInitialOptions()
  addNewRow(rowsClone,data.id)
 
  console.log([...tableManager.rowsApi.rows][1]);
  tableManager.rowEditApi.setEditRowId(rowsClone[0].id);
  }
       })
      .catch((r) => {alert("ERROR")

      tableManager.rowEditApi.setEditRowId(rowsClone[0].id);

      ;})
    //  .catch((r) => {alert("ERROR"); rowsClone.splice(0,1);setRowsData(rowsClone);   addNewRow(rowsClone,data.id); tableManager.rowEditApi.setEditRowId(rowsClone[0].id);})

    }

    
    }}
    >
    {SAVE_SVG}
    </button>
    </div>
    ),
    },
    
    ];
  
   };
   
   
   export default GetColumns;
