import {myConstClass, TENANT_CONFIG_API} from "./constants.js";
import axios from "axios"
const localhost = myConstClass.localhost; 
;

async function loadMetaData(setMetaData, setStateMetataData, setIsExecMetataData,setfsExecMetaData,tenantId)
{
  try {
    // stateResponse
    const [response, isExecResponse, fsExecResponse] = await Promise.all([
        fetch(localhost+"/b2pm/metadata/all?tenantId="+tenantId,{credentials:'include'}),
        // fetch(localhost+"/b2pm/metadata/countryToState",{credentials:'include'}),
        fetch(localhost+"/b2pm/metadata/userbydepartment?department=IS Executive&tenantId="+tenantId,{credentials:'include'}),
        fetch(localhost+"/b2pm/metadata/userbydepartment?department=FS Executive&tenantId="+tenantId,{credentials:'include'})
      ]);
    
    if (response.ok)
    {
      const metadataJson =  await response.json();
      console.log("metadataJson",metadataJson)
      setMetaData(metadataJson);
    }
    // if (stateResponse.ok)
    // {
    //   const stateJson =  await stateResponse.json();
    //   console.log("stateJson",stateJson)
    //   setStateMetataData(stateJson);
    // }
    if (isExecResponse.ok)
    {
      const isExecJson =  await isExecResponse.json();
      console.log("isExecJson",isExecJson)
      setIsExecMetataData(isExecJson);
    }
    if (fsExecResponse.ok)
    {
      const fsExecJson =  await fsExecResponse.json();
      console.log("fsExecJson",fsExecJson)
      setfsExecMetaData(fsExecJson);
    }
    else console.log("Response Bad while fetching metadata"+ response.status + response.statusText)
}
catch(error)
 {
   console.log("Error happened while fetching metadata"+ error)
 }
}

function getKeyNameFromMetaDataName(tenantFieldMapper, metaDataName, hierarchy="") {
  let result = []
  for (let key in tenantFieldMapper){
    let object = tenantFieldMapper[key]
    if (object.metadataname && metaDataName && object.metadataname === metaDataName){
      object.key = key
      result.push(object)  
    }
  }
  console.log("createHierarchy getKeyNameFromMetaDataName ", result, hierarchy)
  return result;
}

// async function createHierarchy(tenantId) {

//   let tenantConfigFieldLabelMapper = {};
//   let metaList = [];
//   let childList=[];
//   let parentList=[];
//   let stateList = [];
//   let isExecList = [];
//   let fsExecList = [];
//   let fmExecList = [];

//   try{
  
//   const [metaResponse, stateResponse, isExecResponse, fsExecResponse, fmExecResponse, tenantConfigResponse] = await Promise.all([
//     fetch(localhost+"/b2pm/metadata/all?tenantId="+tenantId,{credentials:'include'}),
//     fetch(localhost+"/b2pm/metadata/countryToState",{credentials:'include'}),
//     fetch(localhost+"/b2pm/metadata/userbydepartment?department=IS Executive&tenantId="+tenantId,{credentials:'include'}),
//     fetch(localhost+"/b2pm/metadata/userbydepartment?department=FS Executive&tenantId="+tenantId,{credentials:'include'}),
//     fetch(localhost+"/b2pm/metadata/userbydepartment?department=FM Executive&tenantId="+tenantId,{credentials:'include'}),
//     fetch(TENANT_CONFIG_API+tenantId+"/b2am-creation",{credentials:'include'})
//   ]);

//   tenantConfigFieldLabelMapper = await tenantConfigResponse.json();
  
//   if (metaResponse.ok) {
//       metaList =  await metaResponse.json();
//       console.log("metadataJson",metaList)

//       if (stateResponse.ok) {
//         stateList =  await stateResponse.json()
//         console.log("metadataJson state",stateList)
//       } else {
//         stateList = []
//       }

      
//     } else {
//       metaList=[]
//       stateList = []
//     }

//     if (isExecResponse.ok)
//     {
//       isExecList =  await isExecResponse.json();
//       console.log("isExecJson ",isExecList)
      
//     } else {
//       isExecList = []
//     }
//     if (fsExecResponse.ok)
//     {
//       fsExecList =  await fsExecResponse.json();
//       console.log("fsExecJson",fsExecList)
     
//     } else {
//       fsExecList=[]
//     }

//     if (fmExecResponse.ok)
//     {
//       fmExecList =  await fmExecResponse.json();
//       console.log("fsExecJson",fmExecList)
     
//     } else {
//       fmExecList=[]
//     }

  

//   console.log(metaList)

//   for (let i=0; i < metaList?.length; i++) {
//     let tempObj = metaList[i];
//     if (tempObj?.position > 1 && tempObj?.type==="hierarchical") {
//       childList.push(tempObj)
//     } else {
//       parentList.push(tempObj)
//     }
//   }
//   console.log("createHierarchy metalist", metaList)

//   let hierarchyList = []

//   for (let i=0; i < parentList?.length; i++) {
//     let hierarchyObj = {};
//     let tempObj = parentList[i];
    

//     console.log("createHierarchy parent obj", tempObj)

//     // tempObj.name = tenantConfigFieldLabelMapper(tempObj.name)

//     if (tempObj.name==="country") {
//       childElements = [{
//         "name": "state",
//         "position": 1,
//         "data": stateList
//       }]
//     } else {

//     childElements = childList.filter((childObj) => (childObj.hierarchy === tempObj.hierarchy))
//     console.log("createHierarchy child obj", childElements)
//     childElements.sort(function(a, b) {
//       var positionA = a.position,
//       positionB = b.position;
//       if (positionA < positionB) return -1;
//       if (positionA > positionB) return 1;
//       return 0;
//     });
//     hierarchyObj.childs = childElements;
//     console.log("createHierarchy child obj sorted", childElements)
//   }
//   // for let()
//   // let listOfMatchingFieldNames = getKeyNameFromMetaDataName(childObj.name, tenantConfigFieldLabelMapper);
//   //       for(let i=0; i < listOfMatchingFieldNames?.length; i++) {
//   //         tempObj.name = listOfMatchingFieldNames[i].key
//   //         hierarchyObj.parent = tempObj;
//   //         hierarchyList.push(hierarchyObj)
//   //       }
  

//     let listOfMatchingFieldNames = getKeyNameFromMetaDataName(tempObj.name, tenantConfigFieldLabelMapper)
//     for(let i=0; i < listOfMatchingFieldNames?.length; i++) {
//       tempObj.name = listOfMatchingFieldNames[i].key
//       hierarchyObj.parent = tempObj;
//       hierarchyList.push(hierarchyObj)
//     }

    

//   }

//   hierarchyList.push({
//     "name": "ISExec",
//     "position": 1,
//     "data": isExecList
//   })

//   hierarchyList.push({
//     "name": "fieldSalesExec",
//     "position": 1,
//     "data": fsExecList
//   })

//   hierarchyList.push({
//     "name": "fmExec",
//     "position": 1,
//     "data": fmExecList
//   })

//   console.log("createHierarchy resp", JSON.stringify(hierarchyList))
//   }catch(e){
//     console.log("createHierarchy error while processing hierarchy", e)
//   }

// }

async function createHierarchy(tenantId, setAllMetaData, setTenantFieldLabel) {

  let tenantConfigFieldLabelMapper = {};
  let metaList = [];
  let newMetaList = [];
  let stateList = [];
  let isExecList = [];
  let fsExecList = [];
  let fmExecList = [];

  try{
    // stateResponse
  const [metaResponse, isExecResponse, fsExecResponse, fmExecResponse, tenantConfigResponse] = await Promise.all([
    fetch(localhost+"/b2pm/metadata/all?tenantId="+tenantId,{credentials:'include'}),
    // fetch(localhost+"/b2pm/metadata/countryToState",{credentials:'include'}),
    fetch(localhost+"/b2pm/metadata/userbydepartment?department=IS Executive&tenantId="+tenantId,{credentials:'include'}),
    fetch(localhost+"/b2pm/metadata/userbydepartment?department=FS Executive&tenantId="+tenantId,{credentials:'include'}),
    fetch(localhost+"/b2pm/metadata/userbydepartment?department=FM Executive&tenantId="+tenantId,{credentials:'include'}),
    fetch(TENANT_CONFIG_API+tenantId+"/b2am-creation",{credentials:'include'})
  ]);

  tenantConfigFieldLabelMapper = await tenantConfigResponse.json();
  
  if (metaResponse.ok) {
      metaList =  await metaResponse.json();
      console.log("metadataJson",metaList)

      // if (stateResponse.ok) {
      //   stateList =  await stateResponse.json()
      //   console.log("metadataJson state",stateList)
      // } else {
      //   stateList = []
      // }

      
    } else {
      metaList=[]
      stateList = []
    }

    if (isExecResponse.ok)
    {
      let isExecList_temp =  await isExecResponse.json();
      for (let i=0; i < isExecList_temp.length; i++){
        isExecList.push(isExecList_temp[i])
        isExecList[i].value = isExecList_temp[i].name;
      }
      console.log("isExecJson ",isExecList)
      
    } else {
      isExecList = []
    }
    if (fsExecResponse.ok)
    {
      let fsExecList_temp =  await fsExecResponse.json();
      for (let i=0; i < fsExecList_temp.length; i++){
        fsExecList.push(fsExecList_temp[i])
        fsExecList[i].value = fsExecList_temp[i].name;
      }
      console.log("fsExecJson",fsExecList)
     
    } else {
      fsExecList=[]
    }

    if (fmExecResponse.ok)
    {
      let fmExecList_temp =  await fmExecResponse.json();
      for (let i=0; i < fmExecList_temp.length; i++){
        fmExecList.push(fmExecList_temp[i])
        fmExecList[i].value = fmExecList_temp[i].name;
      }
      console.log("fsExecJson",fmExecList)
     
    } else {
      fmExecList=[]
    }

    metaList.push({
    "name": "ISExec",
    "position": 1,
    "type": "categorical",
    "values": isExecList
  })

  metaList.push({
    "name": "fieldSalesExec",
    "position": 1,
    "type": "categorical",
    "values": fsExecList
  })

  metaList.push({
    "name": "fmExec",
    "position": 1,
    "type": "categorical",
    "values": fmExecList
  })

  metaList.push({
    "name": "state",
    "position": 2,
    "hierarchy": "countryClassification",
    "type": "hierarchical",
    "values": stateList
  })

  for (let i=0; i < metaList?.length; i++){
    let tempObj = metaList[i]
    if (tempObj.name === "country") {
      tempObj.position = 1;
      tempObj.hierarchy = "countryClassification";
      tempObj.type = "hierarchical";
    }

    let metaDataFields = getKeyNameFromMetaDataName(tenantConfigFieldLabelMapper, tempObj.name, tempObj.hierarchy)

    for (let  j=0; j < metaDataFields?.length; j++) {
      
      let tempOuterObj = metaList[i];
      if (tempOuterObj.name === "country") {
        tempOuterObj.position = 1;
        tempOuterObj.hierarchy = "countryClassification";
        tempOuterObj.type = "hierarchical";
      }
      let tempMetaObj = metaDataFields[j];
      tempOuterObj.name = tempMetaObj.key
      tempOuterObj.source = tempMetaObj.source 

      console.log("createHierarchy metaDataFields", j, tempOuterObj, tempMetaObj)

      newMetaList.push({...tempOuterObj})
    }


  }
  console.log("createHierarchy response ", newMetaList)
  // setAllMetaData(newMetaList)
  // setTenantFieldLabel(tenantConfigFieldLabelMapper)

  
  setAllMetaData(newMetaList)
  setTenantFieldLabel(tenantConfigFieldLabelMapper)
  }catch(e){
    console.log("createHierarchy error while processing hierarchy", e)
  }

}

export default loadMetaData;
export {createHierarchy};