import React, { useEffect, useState } from "react";
import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {myConstClass} from "./constants.js";
import {useAuthState} from "@bamboobox/b2logincheck";
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import {getAllRowsData, getAllRowsDatawithId, saveAccountfromRow, saveFundingfromRow, saveCRMfromRow} from './functionRepositoryDataSaveGet';
import './toast.css';
import Alert from '@mui/material/Alert';

const localhost = myConstClass.localhost; 

toast.configure({
    autoClose:6000,
    draggable: true,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT
  });

function TypeAheadService(props){
    const userData = useAuthState();
    const [options, setOptions] = useState([]);
    const [disable, setDisable] = useState(false);
    // const [search, setSearch] = useState("");
    let disabled = {disable};
    let tenantId = userData.user.tenantId;
    let userId = userData.user.userUuid;
    let value=props.value
    let onChange=props.onChanged
    let data=props.data
    let column=props.column;
    let optionsFullSuggestions=[]

//    console.log("value = ", value, "data=", data, "column ", column, column.field);

let baseUrl = "/b2pm/account/autocomplete/";
    let url = "";
    let param1 = '*';
    

    
    url = formuLateCompleteURL(baseUrl,column, param1);
    url = formuLateCompleteURL(baseUrl,column, param1);

    function formuLateCompleteURL(url, column, param1)
    {
      let compUrl= url;
      if(column.field == "companyName")
      {
        compUrl += `name?q=${param1}`;
      }
      else if(column.field == "companyWebsite")
      {
        compUrl += `website?q=${param1}`;

      } 
        return compUrl;
    }

  //  let resp = [{"accountId":"9ac08989-b7f1-59d8-a29e-2b938c1a2c31.157c6f70-b5ec-4d7b-afa1-bf0baa26f426","companyName":"jfk","companyWebsite":"abc.co.in","tenantId":"9ac08989-b7f1-59d8-a29e-2b938c1a2c31"},{"accountId":"9ac08989-b7f1-59d8-a29e-2b938c1a2c31.4d709366-c5ca-44b9-b3ae-d5a374357653","companyName":"gfgv","companyWebsite":"jdsf.com","tenantId":"9ac08989-b7f1-59d8-a29e-2b938c1a2c31"},{"accountId":"9ac08989-b7f1-59d8-a29e-2b938c1a2c31.649aa7dc-06cc-4bbb-bd57-02e2ffe662c3","companyName":"Allianz","companyWebsite":"allianz.com","tenantId":"9ac08989-b7f1-59d8-a29e-2b938c1a2c31"},{"accountId":"9ac08989-b7f1-59d8-a29e-2b938c1a2c31.0ba5a49e-40e7-4ba0-a7f6-da74774c4ca1","companyName":"Aryamond","companyWebsite":"aryadiamond.com","tenantId":"9ac08989-b7f1-59d8-a29e-2b938c1a2c31"},{"accountId":"9ac08989-b7f1-59d8-a29e-2b938c1a2c31.2bfe758f-4eb0-4707-93df-ecbefc369912","companyName":"hb","companyWebsite":"hj.com","tenantId":"9ac08989-b7f1-59d8-a29e-2b938c1a2c31"}];
     useEffect(() => {
           console.log("options",options)
       },[options]);
       
  //  let url ="/b2pm/account/autocomplete/name";
  //  if(column==="companyWebsite")
  //  {
  //    url ="/b2pm/account/autocomplete/website";
  //  }   
  
      useEffect(() => {
        // axios.get(localhost+url+"?q=*&tenantId="+tenantId,{withCredentials:true})
        axios.get(localhost+url+"&tenantId="+tenantId,{withCredentials:true})
        .then((response) => {
          try {
        //    let response = {data:[]};
          //response.data = resp;
          
          optionsFullSuggestions=response.data;
          setOptions(response.data);
          console.log("options",options);
          } catch (e) {
            console.log(e)
          }
          
        }).catch((error) => {console.log(error)})
      },[]);

    var onSuggestionSelected=async(selectedValue)=> {
        if (!selectedValue) {
            console.log("initial Load set full values", optionsFullSuggestions)
            setOptions(optionsFullSuggestions)
          return;
        }
        console.log("TypeAheadService data 2", props.data)

        console.log("onChange",selectedValue);
        // onChange({ ...data, [column.field]: value.companyName })
        try {
        let response = await axios.get(localhost+ "/b2pm/account/id?accountid=" + selectedValue.accountId+"&tenantId="+tenantId,{withCredentials:true});
        if (response.status === 200 && response.data.msg ==="SUCCESS") {
          let dataCopy  = getAllRowsDatawithId(response.data, data.id)
          const newData = { ...data, ...dataCopy};
          newData.crmContactGeography = newData.crmAccountGeography;
          newData.contactRegion = newData.companyAccountRegion;
          onChange({ ...newData })
        }
      } catch (e){
        
        toast.error(<Alert severity="error">Error Occured in autocompletion operation</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true,
        });
        console.log(e);
      }
      }
      
  
    var onInputChangeAutoComplete=async(e)=> {
      let valueInput = e.target.value;
      data.accountId="";
      data.contactId="";
      onChange({ ...data, [column.field]: valueInput })
      if (!valueInput || !valueInput.trim() || valueInput.trim()==="*"){
        console.log("input change is empty/whitespace")
        valueInput="*"
      }

      console.log("TypeAheadService data 1", props.data)

      console.log("inputChange",valueInput);
      console.log("for the if condition", (!(valueInput.includes("*") || valueInput.includes("?") || valueInput.length > 2)));
      
//       if (!(valueInput.includes("*") || valueInput.includes("?") || valueInput.length > 2 )) {
//         setOptions([]);
//         console.log("options within if", options);
        
//         return;
//       }
      try{
      const inpValue = valueInput.trim().toLowerCase();
      url = formuLateCompleteURL(baseUrl,column, inpValue);

        // let response = await axios.get(localhost+"/b2pm/account/autocomplete/name?q="+inpValue+"&tenantId="+tenantId,{withCredentials:true});
        let response = await axios.get(localhost+url+"&tenantId="+tenantId,{withCredentials:true});
        let dataJson = response.data;
        setOptions(dataJson);
        
        
      } catch(e) {
        console.log("TypeAheadService error",e)
      }
      return;
    }

    return (
        <Autocomplete
        inputValue={value}
        
        autoComplete  
        autoHighlight
        onChange={(event, newValue) => {
          onSuggestionSelected(newValue);
          
        }}
        
        filterOptions={x => x}
        disabled = {((column.field === "companyName" || column.field === "companyWebsite") && props.data.contactId !=="" && props.tab === "contact"? true : false)}
        options={options}
        getOptionLabel={(option) => (option[column.field])}
        style={{ width: "98%", margin: "2", marginBottom:"1%" }}
        renderInput={(params) => (
          <TextField  {...params} autoComplete="off" variant="standard" onChange={onInputChangeAutoComplete} onKeyDown={(event) => {console.log("Key Down Event")}}/>
        )}
      /> 
    
      )    
}

export default TypeAheadService;
