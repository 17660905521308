import React, { useEffect, useState } from "react";
import {TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {myConstClass} from "./constants.js";
import {useAuthState} from "@bamboobox/b2logincheck";
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import {getAllRowsData, getAllRowsDatawithId, saveAccountfromRow, saveFundingfromRow, saveCRMfromRow} from './functionRepositoryDataSaveGet';
import NameData from "./firstname.json";
import './toast.css';
import Alert from '@mui/material/Alert';

const localhost = myConstClass.localhost; 


toast.configure({
    autoClose:6000,
     draggable: true,
     hideProgressBar: true,
     position: toast.POSITION.BOTTOM_LEFT,
     
       
  });
 

function ContactFirstName(props){
    const userData = useAuthState();
    const [options, setOptions] = useState([]);
    let tenantId = userData.user.tenantId;
    let firstName =userData.user.firstName;
    let companyWebsite = userData.user.companyWebsite;
    let userId = userData.user.userUuid;
    let value=props.value
    let onChange=props.onChanged
    let data=props.data
    let column=props.column;
    let optionsFullSuggestions=[]

   // http://localhost:8080/b2pm/contact/autocomplete/fname?q1=fn&tenantId=9ac08989-b7f1-59d8-a29e-2b938c1a2c31
    // console.log("NameData",NameData);
    // useEffect(() => {
        
    //       console.log("options",options)
    //   },[options]);
//    let url ="/b2pm/account/autocomplete/name";
//    if(column=="companyWebsite")
//    {
//      url ="/b2pm/account/autocomplete/website";
//    }   
// localhost+"/b2pm/contact/autocomplete/fname?q1="+companyName+"&q2"+companyWebsite+"&q3"+firstName+"&tenantId="+tenantId,{credentials:'include'}
      useEffect(() => {
       console.log("in userrr");
      //  axios.get(localhost+"/b2pm/contact/autocomplete/fname?q1"+firstName+"&q2"+companyWebsite+"&tenantId="+tenantId,{credentials:'include'})
      axios.get('./firstname.json')
        .then((response) => {
          try {
          //  optionsFullSuggestions=response.data;
          setOptions(response.firstname)
          } catch (e) {
            console.log(e)
          }
          
        }).catch((error) => {console.log(error)})
      },[]);
  //     const [options, setOptions] = useState([]);
  //  setOptions=()=>{
  //   fetch('data.json'
  //   ,{
  //     headers : { 
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json'
  //      }
  //   }
  //   )
  //     .then(function(response){
  //       return response.json();
  //     })
  //     .then(function(myJson) {
  //       setOptions(myJson)
  //     });
  // }
  // useEffect(()=>{
  //   setOptions()
  // },[])




    var onSuggestionSelected=async(selectedValue)=> {
        if (!selectedValue) {
            console.log("initial Load set full values", optionsFullSuggestions)
            setOptions(optionsFullSuggestions)
          return;
        }
        console.log("onChange",selectedValue);
        // onChange({ ...data, [column.field]: value.companyName })
        try {
        let response = await axios.get(localhost+ "/b2pm/account/id?accountid=" + selectedValue.accountId+"&tenantId="+tenantId,{withCredentials:true});
        console.log("response from backend", response, response.data)
        if (response.status === 200 && response.data.msg ==="SUCCESS") {
          console.log("convert response", getAllRowsDatawithId(response.data, data.id))
          let dataCopy  = getAllRowsDatawithId(response.data, data.id)
          
          
          onChange({ ...data, ...dataCopy})
          // props.value=selectedValue.companyName;
        }
      } catch (e){
        
      toast.error(<Alert severity="error">Error Occured in autocompletion operation</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true,
      });
        console.log(e);
      }
      }
  
  
    var onInputChangeAutoComplete=async(e)=> {
      let valueInput = e.target.value;
      data.accountId="";
      data.contactId="";
      
      onChange({ ...data, [column.field]: valueInput })
      if (!valueInput || !valueInput.trim() || valueInput.trim()==="*"){
        console.log("input change is empty/whitespace")
        valueInput="*"
      }

      console.log("inputChange",valueInput);
      console.log("for the if condition", (!(valueInput.includes("*") || valueInput.includes("?") || valueInput.length > 2)));
      
//       if (!(valueInput.includes("*") || valueInput.includes("?") || valueInput.length > 2 )) {
//         setOptions([]);
//         console.log("options within if", options);
        
//         return;
//       }
      try{
      const inpValue = valueInput.trim().toLowerCase();
        let response = await axios.get(localhost+"/b2pm/contact/autocomplete/fname?q1="+inpValue+"&tenantId="+tenantId,{withCredentials:true});
        let dataJson = response.data;
        setOptions(dataJson);
        
        
      } catch(e) {
        console.log("TypeAheadService error",e)
      }
      return;
    }

    return (
    //   <div className="App">
    //  {
    //    data && data.length>0 && data.map((item)=><p>{item.about}</p>)
    //  }
    // </div>
        <Autocomplete
        inputValue={value}
        autoComplete  
        autoHighlight
        onChange={(event, newValue) => {
          onSuggestionSelected(newValue);
          
        }}
        filterOptions={x => x}
        options={NameData.firstname}
        getOptionLabel={(option) => (option.firstName)}
        style={{ width: "98%", margin: "2", marginBottom:"1%" }}
        renderInput={(params) => (
          <TextField {...params} autoComplete="off" variant="standard" onChange={onInputChangeAutoComplete}/>
        )}
        
     /> 
    
      )
     
    
}


export default ContactFirstName;